import { ref, computed, watch } from '@vue/composition-api'
import { debounce } from 'debounce'

export default function useGetListRacing() {
  const columns = [
    {
      key: 'eventID',
    },
    {
      key: 'eventTitle',
      label: 'Event name',
    },
    {
      key: 'date',
    },
    {
      key: 'time',
      label: 'START TIME',
    },
    {
      key: 'sport',
    },
    {
      key: 'league',
    },
    {
      key: 'eventType',
      label: 'Event type',
    },
    {
      key: 'choose',
      label: 'choose leg',
    },
    // {
    //   key: 'selectMarkets',
    //   label: 'select markets',
    // },
    {
      key: 'bettingStatus',
      label: 'Status',
    },
  ]
  const selectedList = ref([])

  const columnsTableMarket = [
    {
      key: 'selected',
    },
    {
      key: 'name',
    },
    {
      key: 'marketType',
      label: 'market type',
    },
    {
      key: 'numberSelection',
      label: 'No Selections',
    },
  ]

  const refRacingListTable = ref(null)
  // const refEventDetailTable = ref(null)
  const statusOptions = ['All', 'Live', 'Coming Soon', 'Expired']
  const perPageOptions = [15, 30]

  const statusFilter = ref('All')
  const searchQuery = ref('')
  const perPage = ref(15)
  const currentPage = ref(1)
  const totalRaces = ref(0)

  const updateFilter = val => {
    statusFilter.value = val
  }

  const dataMeta = computed(() => {
    const localItemsCount = refRacingListTable.value ? refRacingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRaces.value,
    }
  })

  const refetchData = () => {
    refRacingListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch(
    [searchQuery],
    debounce(() => {
      totalRaces.value = 0
      refetchData()
      // eslint-disable-next-line comma-dangle
    }, 700)
  )

  return {
    columns,
    columnsTableMarket,
    statusOptions,
    perPageOptions,
    // state
    statusFilter,
    searchQuery,
    perPage,
    currentPage,
    totalRaces,
    selectedList,

    // function
    updateFilter,
    // filterResult,
    dataMeta,
    refRacingListTable,
    // refEventDetailTable,
  }
}
