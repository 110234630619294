<!-- eslint-disable vue/no-template-shadow -->
<template>
  <section>
    <b-card v-if="!eventId">
      <div class="m-2">
        <b-row>
          <div class="d-flex my-2">
            <h4 class="mb-0 mr-2">
              Choose sport
            </h4>
            <b-form-radio-group
              id="radio-league"
              v-model="selectedSport"
              :options="optionsSport"
              name="radio-league"
            />
          </div>
        </b-row>
        <b-row>

          <b-col
            cols="12"
            lg="4"
            style="flex-direction: column"
          >
            <label>Start time</label>
            <v-select
              v-model="sortOrderStartTime"
              :options="orderOptions"
            />
          </b-col>
          <b-col
            cols="12"
            lg="8"
            style="display: flex; justify-content: end; height: 40px;margin-top: 20px;"
          >
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleCreateLeg"
            >
              <template> Create leg </template>
            </b-button>
          </b-col>

        </b-row>
      </div>
      <b-table
        ref="refRacingListTable"
        :items="fetchListEvent"
        :fields="columns"
        responsive
        primary-key="racing"
        show-empty
        :no-sort-reset="true"
        sticky-header="800px"
      >
        <template #empty>

          <div
            v-if="fetchListEvent.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>
        <!-- event -->
        <template #cell(event)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.meetingName }}
            </div>
          </b-media>
        </template>
        <!-- raceNumber -->
        <template #cell(raceNumber)="{ item }">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-nowrap text-body cursor-pointer">
              {{ item.number }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(raceName)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.name }}
            </div>
          </b-media>
        </template>
        <!-- raceName -->
        <template #cell(date)="{ item }">
          <b-media vertical-align="center">
            <div
              class="d-flex align-items-center font-weight-bold"
              style="width: 90px"
            >
              {{ formatTime(item.startTime, 'dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- time -->
        <template #cell(time)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime) }}
            </div>
          </b-media>
        </template>

        <!-- choose -->
        <template #cell(choose)="row">
          <b-dropdown
            :id="row.item.id"
            variant="link"
            no-caret
            boundary="viewport"
            :disabled="disableSelectLeg(row.item)"
          >
            <template #button-content>
              <div
                v-if="showLeg(row.item)"
                style="font-weight: 900; display: flex; gap: 4px; align-items: center"
                @click="handleDelete(row)"
              >
                <span>Leg {{ showLeg(row.item).legNumber }}</span>
                <span style="padding: 2px; border: 1px solid grey; color: red">x</span>
              </div>
              <div
                v-else
                style="cursor: pointer"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
              </div>
            </template>
            <template>
              <b-dropdown-item
                v-for="i in legList"
                :key="i.number"
                :disabled="disabledLeg(i)"
                style="text-align: center"
                @click="handleSelectLeg(row.item, i)"
              >
                {{ i.value }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BMedia,
  BCol,
  BRow,
  BCard,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormRadioGroup,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import {
  formatDate, formatDateTimeAustralia, formatDateQuery, formatTime, formatDateTimeLocal,
  formatMarketAlias,
} from '@/helpers/utils'
import { subDays, addDays } from 'date-fns'
import { arrayProp } from '@/helpers/props.js'
import vSelect from 'vue-select'
import useToastHandler from '@/services/toastHandler'
import useGetListRacing from './useGetListRacing.js'

export default {
  components: {
    BTable,
    BMedia,
    BCol,
    BRow,
    BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    vSelect,
    BFormRadioGroup,
  },
  props: {
    listDetailEventProp: arrayProp(),
    // selectedSport: stringProp(),
  },

  data() {
    return {
      eventId: null,
      eventItem: null,
      eventDetailId: null,
      eventDetailItem: null,
      meetingsDated: [],
      listRace: [],
      formatDateTimeAustralia,
      formatDate,
      time: '',
      legList: [
        { number: 1, value: 'Leg 1' },
        { number: 2, value: 'Leg 2' },
        { number: 3, value: 'Leg 3' },
        { number: 4, value: 'Leg 4' },
        { number: 5, value: 'Leg 5' },
        { number: 6, value: 'Leg 6' },
        { number: 7, value: 'Leg 7' },
        { number: 8, value: 'Leg 8' },
      ],
      selectedLegList: null,
      orderOptions: [
        { label: 'ASC', value: 'ASC' },
        { label: 'DESC', value: 'DESC' },
      ],
      sortOrderStartTime: 'ASC',
      sortOrderRunners: '',
      listEvent: [],
      listSeletedLeg: [],
      listDetailEvent: [],
      listSeletedMarkets: [],
      selectedRowId: 2,
      isEdit: false,

      selectedListFormat: [],

      optionsSport: [],
      selectedSport: 'AFL',
    }
  },
  computed: {
    // ...mapState('races/listRace', ['tokenBetMaket', 'expireAt']),
    // ...mapState('races/listRace', ['listEventPicked', 'racePicked', 'pickFrontBack', 'pickFrontBackNumber']),
    ...mapState('sportComp/eventDetail', ['loading', 'detailEvent']),
    ...mapState('races/compDetail', ['dataDetail']),
    converTime() {
      return new Date(this.time).toISOString()
    },
  },
  watch: {
    sortOrderStartTime(val) {
      if (val) this.sortOrderRunners = ''
      this.handleSortList()
    },
    sortOrderRunners(val) {
      if (val) this.sortOrderStartTime = ''
      this.handleSortList()
    },
    time(val) {
      if (val) this.sortOrderRunners = ''
      if (val) this.sortOrderStartTime = ''
    },
    selectedList(items) {
      const newList = items.map(i => ({
        ...i,
      }))
      this.listDetailEvent = this.listDetailEvent.map(event => {
        const newListMarket = []
        newList.forEach(x => {
          if (x.eventID === event.eventID) newListMarket.push(x)
        })
        return {
          ...event,
          listMaxSelect: newListMarket,
        }
      })
    },
    selectedSport() {
      this.refRacingListTable.refresh()
    },
    optionsSport() {
      this.refRacingListTable.refresh()
    },
  },
  emits: ['updateStatusFilter'],
  async created() {
    const res = await this.getListLeaguesSport()
    if (Array.isArray(res.data)) {
      const formatList = res.data.map(i => ({
        text: i.sportLeagueId,
        value: i.sportLeagueId,
        sportCode: i.sportCode,
      }))
      this.optionsSport = formatList
    }
    // const res = await this.getListEvent(this.selectedSport)
    // this.listEvent = res.filter(i => !i.eventTitle.toLowerCase().includes('specials'))
    this.listDetailEvent = this.listDetailEventProp
    const list = this.listDetailEventProp.map(i => ({
      ...i,
      legNumber: i.number,
    }))
    this.listSeletedLeg = list

    let listAllMarket = []
    this.listDetailEventProp.forEach(i => {
      listAllMarket = [...listAllMarket, ...i.markets]
    })
    this.selectedList = listAllMarket.map(i => ({
      eventID: i.eventID,
      marketType: i.marketType,
      name: i.name,
      selections: i.selections,
      startTime: i.startTime,
    }))
    if (listAllMarket.length > 0) this.isEdit = true
  },
  methods: {
    ...mapActions({
      getListEvent: 'sportComp/listEvent/getListEvent',
      getDetail: 'sportComp/eventDetail/getDetail',
      getListLeaguesSport: 'sportComp/listEvent/getListLeaguesSport',
    }),
    async fetchListEvent() {
      if (this.optionsSport.length === 0) return
      const sport = this.optionsSport.find(i => i.value === this.selectedSport)
      const res = await this.getListEvent({
        sportCode: sport.sportCode,
        value: this.selectedSport,
      })
      if (Array.isArray(res)) {
        this.listEvent = res.filter(i => !i.eventTitle.toLowerCase().includes('specials'))
        return this.listEvent
      }
      return []
    },
    onChangeFilter(selected) {
      this.$emit('updateStatusFilter', selected)
    },
    handleSortList() {
      if (this.sortOrderRunners && this.sortOrderRunners.value) {
        const val = this.sortOrderRunners.value
        if (val === 'DESC') return (this.listRace = this.listRace.sort((a, b) => b.competitors.length - a.competitors.length))
        if (val === 'ASC') return (this.listRace = this.listRace.sort((a, b) => a.competitors.length - b.competitors.length))
      }
      if (this.sortOrderStartTime && this.sortOrderStartTime.value) {
        const val = this.sortOrderStartTime.value
        if (val === 'DESC') {
          return (this.listEvent = this.listEvent.sort(
            (a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
          ))
        }
        if (val === 'ASC') {
          return (this.listEvent = this.listEvent.sort(
            (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
          ))
        }
      }
    },
    dateDisabled(ymd, date) {
      return date < subDays(new Date(), 1) || date > addDays(new Date(), 3)
    },
    disabledLeg(item) {
      if (this.listSeletedLeg.length === 0) return false
      // eslint-disable-next-line no-shadow
      const itm = this.listSeletedLeg.find(i => i.legNumber === item.number)
      if (itm && itm.eventID) return true
      return false
    },
    showLeg(item) {
      const itm = this.listSeletedLeg.find(i => i && i.eventID === item.eventID)
      if (itm && itm.eventID) return itm
      return ''
    },
    async handleSelectLeg(item, i) {
      const res = await this.handleSelectRow(item, i)
      if (!res) return
      const newItem = {
        ...item,
        legNumber: i.number,
      }
      this.listSeletedLeg.push(newItem)
    },
    handleDelete(row) {
      const item = row.item
      this.listSeletedLeg = this.listSeletedLeg.filter(i => i.eventID !== item.eventID)
      this.listDetailEvent = this.listDetailEvent.filter(i => i.eventID !== item.eventID)
      this.selectedList = this.selectedList.filter(i => i.eventID !== item.eventID)
    },
    handleCreateLeg() {
      this.$emit('create-leg', this.listDetailEvent)
    },
    async handleSelectRow(item, num) {
      const list = []
      // const listAFL = []
      // const listNRL = []
      const findItem = this.listDetailEvent.find(i => i.eventID === item.eventID)
      const res = await this.getDetail(item.eventID)
      // const checkScorerItem = res.markets.find(i => i.name.toLowerCase() === 'anytime try scorer')
      const checkMatchBettingItem = res.markets.find(i => i.name === res.primaryMarketName)
      // const checkDisposalsItem = res.markets.find(i => i.name.toLowerCase() === 'player goals - anytime goal kicker')

      // if (this.selectedSport === 'NRL') {
      //   if (checkMatchBettingItem) {
      //     listNRL.push({
      //       ...checkMatchBettingItem, maxSelect: '1', marketAlias: formatMarketAlias(checkMatchBettingItem.name), marketLayout: 'line',
      //     })
      //   }
      // }
      // if (this.selectedSport === 'AFL') {
      //   if (checkMatchBettingItem) {
      //     listAFL.push({
      //       ...checkMatchBettingItem, maxSelect: '1', marketAlias: formatMarketAlias(checkMatchBettingItem.name), marketLayout: 'line',
      //     })
      //   }
      // }
      if (checkMatchBettingItem) {
        list.push({
          ...checkMatchBettingItem, maxSelect: '1', marketAlias: formatMarketAlias(checkMatchBettingItem.name), marketLayout: 'line',
        })
      }

      const team = checkMatchBettingItem.selections.filter(i => i.role !== 'Draw')

      team.sort((a, b) => {
        if (a.role === 'HomeTeam') return -1
        if (b.role === 'HomeTeam') return 1
        return 0
      })

      const scores = team.map(sel => {
        const role = sel.role === 'Away' ? 'AwayTeam' : 'HomeTeam'
        return {
          role,
          team: sel.name,
          flag: '',
          subFlag: '',
          teamAlias: '',
        }
      })
      const data = {
        ...res,
        number: num.number,
        // markets: this.selectedSport === 'NRL' ? listNRL : listAFL,
        markets: list,
        scores: res.scores.length > 0 ? res.scores.sort((a, b) => {
          if (a.role === 'HomeTeam') return -1
          if (b.role === 'HomeTeam') return 1
          return 0
        }) : scores,
      }
      if (!findItem && res) {
        this.listDetailEvent.push(data)
        return true
      }
    },
    selectItem(item) {
      return this.listDetailEvent.find(i => i.eventID === item.eventID)
    },
    selectItemMarkets(item) {
      return this.listDetailEvent.find(i => i.eventID === item.eventID)?.markets
    },
    checkSelectedMarkets(item) {
      const seleteItem = this.selectedList.find(i => i.name === item.name)
      return !!seleteItem
    },
    disableSelectLeg(item) {
      if (item.bettingStatus !== 'BettingOpen') return true
      if (this.$route.name === 'sport-comp-create') return false
      const listEvents = this.dataDetail.events.find(i => i.eventId === item.eventID)
      return !!listEvents
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const {
      columns,
      columnsTableMarket,
      statusOptions,
      perPageOptions,
      statusFilter,
      searchQuery,
      perPage,
      updateFilter,
      currentPage,
      totalRaces,
      refRacingListTable,
      selectedList,
      dataMeta,

    } = useGetListRacing()
    return {
      columns,
      columnsTableMarket,
      statusOptions,
      perPageOptions,
      statusFilter,
      searchQuery,
      perPage,
      updateFilter,
      currentPage,
      totalRaces,
      refRacingListTable,
      dataMeta,
      formatDateQuery,

      formatTime,
      formatDateTimeLocal,
      selectedList,

      showToastSuccess,
      showToastError,

    }
  },
}
</script>

<style lang="scss">
.dark-layout .dropdown-menu .dropdown-item.disabled {
  color: red !important;
}
</style>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.v-select .vs__dropdown-toggle,
.v-select .vs__search {
  cursor: pointer;
}

.card-body {
  padding: 0 !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
