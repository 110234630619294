<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            UPDATE COMP
          </h4>
        </div>

        <validation-observer ref="infoCompForm">
          <!-- Form: Information Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- COMP NAME -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (logged in)</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="compName"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- COMP NAME -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (not logged in)</label>
                  </div>
                  <b-form-input
                    v-model="guestName"
                    type="text"
                  />
                </b-form-group>
              </b-col>

              <!-- Showdown Open Date/Time -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="openDate">Showdown Open Date/Time</label>
                  </div>
                  <validation-provider
                    name="openDate"
                    vid="openDate"
                  >
                    <flat-pickr
                      id="openDate"
                      v-model="openDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                        // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- First Leg Start Date/Time -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="competitionDate">First Leg Start Date/Time</label>
                  </div>
                  <validation-provider
                    name="competitionDate"
                    vid="competitionDate"
                  >
                    <flat-pickr
                      id="competitionDate"
                      v-model="competitionDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                        // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--  END DATE/TIME -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="END DATE/TIME">END DATE/TIME</label>
                  </div>
                  <validation-provider
                    name="endTime"
                    vid="endTime"
                  >
                    <flat-pickr
                      id="endDate"
                      v-model="endDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:s',
                        enableSeconds: true,
                        disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                      }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--MORE INFO -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="more info">MORE INFO</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="moreInfo"
                    vid="moreInfo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="moreInfo"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--REMEMBER -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="remember">REMEMBER</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="remember"
                    vid="remember"
                    rules="required"
                  >
                    <b-form-input
                      v-model="remember"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--SCRATCHINGS -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="scratchings">SCRATCHINGS</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="scratchings"
                    vid="scratchings"
                    rules="required"
                  >
                    <b-form-input
                      v-model="scratchings"
                      type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- ENTRY FEE -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="entry fee">ENTRY FEE</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="priceTo"
                    vid="priceTo"
                    rules="required"
                  >
                    <b-form-input
                      v-model="priceTo"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- RE BUY FEE -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">RE BUY FEE</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="latePrice"
                    vid="latePrice"
                    rules="required"
                  >
                    <b-form-input
                      v-model="latePrice"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- LIMIT REAL BETSLIP -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">LIMIT BETSLIP</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="limit betslip"
                    vid="limit betslip"
                    rules="required"
                  >
                    <b-form-input
                      id="limit-value"
                      v-model="limitBetslip"
                      :formatter="formatNumberBetslip"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- MANUAL BETSLIP -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">MANUAL BETSLIP ENTRY (optional)</label>
                  </div>
                  <b-form-input
                    id="limit-value"
                    v-model="manualBetSlipEntry"
                    :formatter="formatNumberBetslip"
                  />
                </b-form-group>
              </b-col>

              <!-- Api Price -->
              <b-col
                cols="12"
                md="6"
                lg="4"
              >
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">BONUS ADDITIONAL TICKET (optional)</label>
                  </div>
                  <b-form-input
                    v-model="apiPrice"
                    min="0"
                    type="number"
                    :no-wheel="true"
                    placeholder="Enter 100 for 0.5 tickets @ $50"
                  />
                </b-form-group>
              </b-col>

              <!--PARTICIPANTS DESCRIPTIONS -->
              <b-col cols="12">
                <b-form-group>
                  <div class="d-flex">
                    <label for="participants descriptions">PARTICIPANTS DESCRIPTIONS</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="participantsDes"
                    vid="participantsDes"
                    rules="required|limitCharacters"
                  >
                    <vue-editor
                      v-model="participantsDes"
                      name="participantsDes"
                      :editor-toolbar="customToolbar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                class="font-weight-bold d-flex justify-content-between mb-1"
              >
                <span>FLEXI OPTION</span>
                <b-button
                  variant="primary"
                  size="md"
                  @click="handleAddMoreOptionFlexi"
                >
                  + Add More
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col
                    v-for="(item, index) in optionsFlexi"
                    :key="index"
                    cols="12"
                    md="4"
                    class="mb-1"
                  >
                    <div class="d-flex justify-content-between mb-1">
                      <span class="d-block"> {{ toCardinal(index + 1) }} Option (%)</span>
                      <b-button
                        variant="danger"
                        size="sm"
                        @click="handleRemoveOptionFlexi"
                      >
                        x
                      </b-button>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      :name="toCardinal(index + 1) + ' Option'"
                      rules="required"
                    >
                      <b-form-input
                        v-model="optionsFlexi[index]"
                        :formatter="formatNumberFlexi"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- </label> -->
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <!-- BANNER WEB -->
            <!-- <b-row>
              <b-media
                no-body
                class="d-flex flex-column"
              >
                <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                  <h6 class="font-weight-bold mr-75">
                    BANNER WEBSITE
                  </h6>
                  <b-button
                    e
                    variant="primary"
                    size="sm"
                    class="mr-75"
                    @click="$refs.refInputEl.$el.click()"
                  >
                    Upload
                  </b-button>

                  <b-form-file
                    ref="refInputEl"
                    accept=".jpg, .png, .gif, .heic, .jpeg"
                    :hidden="true"
                    plain
                    @change="inputImageRenderer"
                  />

                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mr-75"
                    @click="removeImageRenderer"
                  >
                    Reset
                  </b-button>
                </b-media-body>
                <b-media-aside>
                  <b-img
                    v-if="fileImage"
                    class="banner-img ml-75"
                    :src="fileImage"
                    alt="logo"
                  />
                </b-media-aside>
              </b-media>
            </b-row>
            <small
              v-if="errorBannerWeb"
              class="text-danger mb-75"
            >Please upload banner image</small> -->

            <!-- BANNER MOBILE -->
            <!-- <b-row>
              <b-media
                no-body
                class="d-flex flex-column"
              >
                <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                  <h6 class="font-weight-bold mr-75">
                    BANNER MOBILE
                  </h6>
                  <b-button
                    e
                    variant="primary"
                    size="sm"
                    class="mr-75"
                    @click="$refs.refInputMobileEl.$el.click()"
                  >
                    Upload
                  </b-button>

                  <b-form-file
                    ref="refInputMobileEl"
                    accept=".jpg, .png, .gif, .heic, .jpeg"
                    :hidden="true"
                    plain
                    @change="inputMobileImageRenderer"
                  />

                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mr-75"
                    @click="removeMobileImageRenderer"
                  >
                    Reset
                  </b-button>
                </b-media-body>
                <b-media-aside>
                  <b-img
                    v-if="fileMobileImage"
                    class="banner-img ml-75"
                    :src="fileMobileImage"
                    alt="logo"
                  />
                </b-media-aside>
              </b-media>
            </b-row>
            <small
              v-if="errorBannerMobile"
              class="text-danger mb-75"
            >Please upload banner image</small> -->

            <!-- FLEXI ROLL OVER POOL -->
            <b-row class="mb-1 mt-2 d-flex align-items-start">
              <b-col
                cols="3"
                class="font-weight-bold"
              >
                <h4 class="mb-0">
                  Use Flexi Roll Over Pool
                </h4>
              </b-col>
              <b-col
                cols="9"
                style="display: flex; align-items: center"
              >
                <b-form-radio-group
                  id="radio-inline-flexi"
                  v-model="selectedFlexiRollOverPool"
                  :disabled="new Date() >= new Date(comp.morningLockout)"
                  :options="optionsFlexiRollOverPool"
                  name="radio-inline-flexi"
                />
              </b-col>
            </b-row>
            <b-row>
              <template v-if="selectedFlexiRollOverPool">
                <div
                  class="d-flex"
                  style="gap: 10px"
                >
                  <div style="display: flex; align-items: center; justify-content: center">
                    <span style="margin-right: 20px; margin-left: 10px; font-weight: bold">Roll Over Amount($)</span>
                    <b-form-input
                      v-model="flexiRollOverAmount"
                      :disabled="new Date() >= new Date(comp.morningLockout)"
                      min="0"
                      type="number"
                      :no-wheel="true"
                      style="max-width: 100px; text-align: center"
                      :formatter="formatPriceRollOver"
                    />
                    <small
                      style="margin-right: 20px; margin-left: 10px; font-weight: bold"
                    >Max: {{ formatCurrencyBuiltInFunc(maxValueFlexiRollOver()) }}</small>
                  </div>
                </div>
              </template>
            </b-row>
            <div
              class="d-flex"
              style="flex-direction: column"
            >
              <small
                v-if="errorFlexiRollOverAmount"
                class="text-danger mb-75"
              >Roll over amount is required</small>
            </div>

            <!-- PRIZE TYPE -->
            <b-row class="mt-2 d-flex align-items-start">
              <b-col
                cols="3"
                class="font-weight-bold"
              >
                <h4>Transfer Prize By</h4>
              </b-col>
              <b-col cols="9">
                <b-form-radio-group
                  id="transfer-prize"
                  v-model="selectedTransferPrize"
                  :options="optionsTransferPrizeBy"
                  name="transfer-prize"
                />
              </b-col>
            </b-row>

            <b-row>
              <div
                class="mr-4 d-flex align-items-center"
                style="padding: 14px;"
              >
                <h4 class="mb-0 mr-2">
                  Lockout time
                </h4>

                <b-dropdown
                  variant="link"
                  no-caret
                  boundary="viewport"
                >
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <div
                        v-if="lockLegFirstData && !lockLeg"
                        class="mr-2"
                      >
                        {{ formatTime(lockLegFirstData, 'HH:mm aa dd-MM-yyyy') }}
                      </div>
                      <div
                        v-if="lockLeg"
                        class="mr-2"
                      >
                        {{ formatTime(lockLeg.value.startTime, 'HH:mm aa dd-MM-yyyy') }} (Leg {{ lockLeg.legId }})
                      </div>
                      <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      >
                        <template>
                          Choose leg
                        </template>
                      </b-button>

                    </div>
                  </template>
                  <template>
                    <b-dropdown-item
                      v-for="i in legList"
                      :key="i.number"
                      style="text-align: center"
                      @click="handleSelectLeg(i)"
                    >
                      {{ i.value }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </b-row>

            <b-row class="mt-4 mb-4">
              <b-col class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="loadingUpdateComp"
                  @click="handleUpdateComp"
                >
                  <Spinner v-if="loadingUpdateComp" />
                  <template v-else>
                    Save changes
                  </template>
                </b-button>
                <b-button
                  variant="outline-secondary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="resetData"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>

            <div class="d-flex my-2 align-items-center">
              <h4 class="mb-0 mr-2">
                COMP LEGS
              </h4>
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleApplyAllMarket"
              >
                <template>
                  Apply markets for all events
                </template>
              </b-button>
            </div>

            <!-- <div class="d-flex my-2">
              <h4 class="mb-0 mr-2">
                Choose sport
              </h4>
              <b-form-radio-group
                id="radio-list-sport"
                v-model="selectedSport"
                :options="optionsSport"
                :disabled="checkDisableSelectSport()"
                name="radio-list-sport"
              />
              <div v-if="checkChangeSport()">
                <feather-icon
                  size="21"
                  icon="CheckIcon"
                  class="cursor-pointer"
                  style="color: green"
                  @click="handleChangeSport()"
                />
              </div>
            </div> -->

            <!-- LEG -->
            <b-row class="">
              <b-col cols="12">
                <!-- FRONT ENTRIES -->

                <div
                  v-for="(item, ind) in frontEntryList"
                  :key="ind"
                >
                  <b-row
                    v-if="!loadingListMarket"
                    class="mb-1 d-flex align-items-center"
                  >
                    <b-col
                      cols="3"
                      class="font-weight-bold"
                    >
                      <span>{{ item.title }}</span>
                    </b-col>
                    <b-col cols="7">
                      <div
                        class="w-full d-flex"
                        style="
                          flex-direction: column;
                          min-height: 38px;
                          padding: 6px 14px;
                          border: 1px solid;
                          border-radius: 6px;
                          white-space: wrap;
                        "
                      >
                        <span
                          v-if="item.value.eventTitle"
                          style="font-weight: bold"
                        > Event: ({{ item.value.league }})</span>
                        <span> {{ item.value.eventTitle }}</span>
                        <span v-if="item.value.startTime"> ( {{ formatDateTimeLocal(item.value.startTime) }} )</span>
                        <div v-if="item.value">
                          <div
                            class="d-flex"
                            style="align-items: center; gap: 5px"
                          >
                            <span class="d-flex align-items-center"> <span style="font-weight: bold">Stadium:</span> <b-form-input
                              v-model="item.value.stadium"
                              type="text"
                              style="padding: 2px; width: 150px; text-align: center; margin-left: 10px;"
                            /> </span>
                            <div v-if="checkIsChangeStadium(item)">
                              <feather-icon
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: green"
                                @click="handleChangeStadium(item)"
                              />
                            </div>
                          </div>
                          <div>
                            <div
                              class="d-flex"
                              style="gap: 5px"
                            >
                              <span style="font-weight: bold">Flag:</span>

                              <feather-icon
                                v-if="checkShowIconChangeFlag(item.value)"
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: red"
                                @click="handleChangeFlag(item.value)"
                              />
                            </div>
                            <div
                              v-for="(role, stt) in item.value.scores"
                              :key="role.role"
                            >
                              <div
                                style="margin-bottom: 2px; display: flex; align-items: center"
                              >
                                <span>{{ `${stt + 1}. Role: ${role.role === 'HomeTeam' ? 'Home' : 'Away'}` }}</span>

                                <div
                                  v-if="!role.flag"
                                  style="cursor: pointer"
                                  @click="handleClick(item, role, 'front')"
                                >
                                  <feather-icon
                                    icon="UploadIcon"
                                    size="16"
                                    style="margin-left: 8px"
                                  /> Upload Team Character
                                </div>
                                <b-img
                                  v-if="role.flag"
                                  style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px"
                                  :src="role.flag"
                                  alt="logo"
                                  @click="handleClick(item, role, 'front')"
                                />
                                <div
                                  v-if="!role.subFlag"
                                  style="cursor: pointer"
                                  @click="handleClickSubFlag(item, role, 'front')"
                                >
                                  <feather-icon
                                    icon="UploadIcon"
                                    size="16"
                                    style="margin-left: 8px"
                                  /> Upload Flag
                                </div>
                                <b-img
                                  v-if="role.subFlag"
                                  style="width: 30px; height: 30px; cursor: pointer; margin-left: 10px;"
                                  :src="role.subFlag "
                                  alt="logo"
                                  @click="handleClickSubFlag(item, role, 'front')"
                                />

                                <b-form-file
                                  :ref="`${item.value.eventID}-${role.role}-flag`"
                                  accept=".jpg, .png, .gif, .heic, .jpeg"
                                  :hidden="true"
                                  plain
                                  style="cursor: pointer"
                                  @change="inputImageRendererFlag"
                                />
                                <b-form-file
                                  :ref="`${item.value.eventID}-${role.role}-subflag`"
                                  accept=".jpg, .png, .gif, .heic, .jpeg"
                                  :hidden="true"
                                  plain
                                  style="cursor: pointer"
                                  @change="inputImageRendererSubFlag"
                                />

                              </div>
                              <div class="d-flex align-items-center px-2 mt-1">
                                <span style="margin-right: 5px;">teamAlias: </span>
                                <b-form-input
                                  v-model="role.teamAlias"
                                  type="text"
                                  style="padding: 2px; width: 70px; text-align: center;"
                                  :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                                />
                                <feather-icon
                                  v-if="checkIsChangeTeamAlias(item.value, role.role)"
                                  size="21"
                                  icon="CheckIcon"
                                  class="cursor-pointer"
                                  style="color: red; margin-left: 10px;"
                                  @click="handleChangeteamAlias(item.value)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex mt-1 mb-1">

                          <span
                            v-if="item.value.markets"
                            style="font-weight: bold"
                          > Markets:</span>
                          <b-button
                            v-if="item.value && checkShowIconRemove(item)"
                            variant="primary"
                            class="mb-1 mb-sm-0 add-btn ml-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            @click="handleShowModalDetail(item.value)"
                          >
                            <template> Add more markets </template>
                          </b-button>
                        </div>
                        <span
                          v-for="(i, ind) in item.value.markets"
                          :key="ind"
                        >
                          <span
                            class="d-flex"
                            style="gap: 4px;"
                          >
                            <span>
                              {{ `${ind + 1}, ${i.name}` }}

                            </span>
                            <b-button
                              v-if="checkRemoveMarKet(item)"
                              variant="outline-danger"
                              class="mb-2 mb-sm-0 add-btn"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                              @click="handleRemoveOldMarket(i, item)"
                            >
                              <template> x </template>
                            </b-button>
                            <b-button
                              v-if="checkShowIconRemove(item)"
                              variant="outline-danger"
                              class="mb-2 mb-sm-0 add-btn"
                              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                              :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                              @click="handleRemoveMarket(i, item)"
                            >
                              <template> x </template>
                            </b-button>

                            <div
                              v-if="!i.imageUrl"
                              style="cursor: pointer"
                              @click="handleUploadImage(item, i)"
                            >
                              <feather-icon
                                icon="UploadIcon"
                                size="16"
                                style="margin-left: 8px"
                              /> Upload Image
                            </div>
                            <b-img
                              v-if="i.imageUrl"
                              style="width: 25px; height: 25px; cursor: pointer; margin-left: 10px;"
                              :src="i.imageUrl"
                              alt="image"
                              @click="handleUploadImage(item, i)"
                            />
                            <b-form-file
                              :ref="`${item.value.eventID}-${i.externalID}-image`"
                              accept=".jpg, .png, .gif, .heic, .jpeg"
                              :hidden="true"
                              plain
                              style="cursor: pointer"
                              @change="inputImageRendererImageMarket"
                            />
                            <div v-if="checkIsChangeImageMarket(item, i)">
                              <feather-icon
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: green"
                                @click="handleChangeImageMarket(item, i)"
                              />
                            </div>

                          </span>
                          <br>
                          <div
                            class="d-flex"
                            style="align-items: center; gap: 5px; margin-top: 3px; margin-bottom: 3px;"
                          >
                            <span style="font-size: 12px">{{ `MarketAlias: ` }}</span>
                            <b-form-input
                              v-model="i.marketAlias"
                              :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                              type="text"
                              style="max-width: 70px; height: 30px; text-align: center; padding: 0"
                            />
                            <div v-if="checkIsChangeMarketAlias(item, i)">
                              <feather-icon
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: green"
                                @click="handleChangeMarketAlias(item, i)"
                              />
                            </div>
                            <span style="font-size: 12px">{{ `Number selected: ` }}</span>
                            <b-form-input
                              v-model="i.maxSelect"
                              type="number"
                              style="max-width: 30px; height: 30px; text-align: center; padding: 0"
                              :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                              :formatter="formatNumberBetslip"
                            />

                            <div v-if="checkIsChangeLimitMarket(item, i)">
                              <feather-icon
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: green"
                                @click="handleChangeLimitSelectedMarket(item, i)"
                              />
                            </div>
                            <span style="font-size: 12px">{{ `Market Layout: ` }}</span>
                            <b-form-radio-group
                              :id="`market-${i.marketId ? i.marketId :i.externalID}`"
                              v-model="i.marketLayout"
                              :options="optionsUI"
                              :name="`market-${i.marketId ? i.marketId :i.externalID}`"
                              style="display: flex; flex-direction: column"
                            />
                            <div v-if="checkIsChangeMarketLayout(item, i)">
                              <feather-icon
                                size="21"
                                icon="CheckIcon"
                                class="cursor-pointer"
                                style="color: green"
                                @click="handleChangeMarketLayout(item, i)"
                              />
                            </div>
                          </div>
                        </span>

                        <!-- FEATURED MARKET -->
                        <div
                          v-if="item.value.markets"
                          class="d-flex align-items-center"
                        >
                          <p class="mb-0 mr-2">
                            Featured Market
                          </p>

                          <b-dropdown
                            variant="link"
                            no-caret
                            boundary="viewport"
                          >
                            <template #button-content>
                              <div class="">
                                <div
                                  v-if="item.value.featuredMarket"
                                >
                                  {{ item.value.featuredMarket.name }}
                                </div>
                                <b-button
                                  variant="primary"
                                  class="mb-sm-0"
                                  style="padding: 6px; margin-top: 4px;"
                                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                                >
                                  <template>
                                    Choose Market
                                  </template>
                                </b-button>

                              </div>
                            </template>
                            <template>
                              <b-dropdown-item
                                v-for="(i, indx) in item.value.markets"
                                :key="i.number"
                                @click="handleSelectFeatureMarket(i, item)"
                              >
                                {{ `${indx + 1}, ${i.name}` }}
                              </b-dropdown-item>
                            </template>
                          </b-dropdown>
                        </div>
                        <div
                          v-if="item.value.featuredMarket"
                          class="d-flex"
                          style="gap: 4px; align-items: center;"
                        >
                          <p class="mt-1 mr-1">
                            Display Name
                          </p>
                          <b-form-input
                            v-model="item.value.featuredMarket.displayName "
                            type="text"
                            style="max-width: 150px; height: 30px; padding: 8px;"
                          />
                          <b-button
                            v-if="checkChangeFeatureMarket(item)"
                            variant="outline-warning"
                            style="padding: 6px; "
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            @click="handleSaveChangeFeaturedMarket(item)"
                          >
                            <template>
                              Save featured market
                            </template>
                          </b-button>
                        </div>

                      </div>
                    </b-col>
                    <b-col
                      cols="2"
                      class="d-flex"
                    >
                      <b-button
                        v-if="!item.value"
                        variant="primary"
                        class="mb-1 mb-sm-0 add-btn"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                        @click="handleCreateLeg"
                      >
                        <template> + </template>
                      </b-button>
                      <!-- <b-button
                        v-else-if="item.value.markets.length === 0"
                        variant="primary"
                        class="mb-1 mb-sm-0 add-btn"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        :disabled="new Date() >= new Date(comp.openForEntry)"
                        style="font-size: 10px; width: 50px"
                        @click="handleUpdateMarket()"
                      >
                        <template> Update market </template>
                      </b-button> -->
                      <b-button
                        v-else
                        variant="primary"
                        class="mb-1 mb-sm-0 remove-btn"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                        @click="handleRemoveLeg(item)"
                      >
                        <template> - </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>

            <div
              v-if="loadingListMarket"
              style="display: flex; height: 200px; align-items: center; justify-content: center; width: 100%"
            >
              <b-spinner
                medium
                variant="secondary"
              />
            </div>

            <!-- LEG -->

            <b-row class="ml-4 mb-4">
              <b-col class="d-flex justify-content-center">
                <b-button
                  variant="success"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="new Date() >= new Date(comp.openForEntry) && comp.status !== 'draft'"
                  @click="handleUpdateListEvent"
                >
                  <Spinner v-if="loadingUpdateListEvent" />
                  <template v-else>
                    Save change list event
                  </template>
                </b-button>
              </b-col>
            </b-row>

            <!-- Header: Personal Info -->
            <div class="d-flex mt-4 mb-2">
              <feather-icon
                icon="BookIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                UPDATE PRIZE
              </h4>
            </div>

            <!-- Get Prize From -->
            <b-row class="mb-2 mt-1 d-flex align-items-start">
              <b-col
                cols="3"
                class="font-weight-bold"
              >
                <span>Get Prize From </span>
              </b-col>
              <b-col
                cols="9"
                style="display: flex; gap: 4px; align-items: center"
              >
                <b-form-radio-group
                  v-model="selectedPrizeFrom"
                  :options="optionsPrizeFrom"
                  name="radio-inline"
                  :disabled="disableChangeMode"
                />
                <b-button
                  v-if="dataDetail.prizeMode !== selectedPrizeFrom"
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="loadingAddEntry"
                  @click="handleUpdateGetPrizeFrom"
                >
                  <Spinner v-if="loadingAddEntry" />
                  <template v-else>
                    Save
                  </template>
                </b-button>
                <input
                  ref="fileInput"
                  type="file"
                  accept=".csv"
                  hidden
                  @change="handleFileUpload"
                >
                <b-button
                  v-if="dataDetail.prizeMode === selectedPrizeFrom && dataDetail.prizeMode === 'manual'"
                  variant="danger"
                  size="md"
                  class="ml-2"
                  @click="onClickInput"
                >
                  Import File
                </b-button>
              </b-col>
            </b-row>

            <!-- Main-Prize -->
            <b-row
              v-if="dataDetail.prizeMode === 'manual' && selectedPrizeFrom === 'manual' && !disableChangeMode"
              class="mb-1 d-flex align-items-start"
            >
              <b-col
                cols="12"
                class="font-weight-bold d-flex justify-content-between mb-1"
              />
              <b-col cols="12">
                <b-row>
                  <b-col
                    v-for="(item, index) in mainPrizes"
                    :key="index"
                    cols="12"
                    md="4"
                    class="mb-1"
                  >
                    <div class="d-flex justify-content-between mb-1">
                      <span class="d-block"> {{ toCardinal(index + 1) }} Prize</span>
                      <div>
                        <b-button
                          variant="primary"
                          style="
                            padding: 4px;
                            font-size: 10px;
                          "
                          @click="handleRemoveMoreInputMainPrize(item)"
                        >
                          Edit
                        </b-button>
                        <b-button
                          v-if="index === mainPrizes.length - 1"
                          variant="gradient-danger"
                          size="sm"
                          style="margin-left: 2px; padding: 2px !important; padding-right: 3px; padding-left: 3px"
                          @click="handleDeletePrize(item)"
                        >
                          x
                        </b-button>
                      </div>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="prize"
                      rules="required"
                    >
                      <b-form-input
                        v-model="mainPrizes[index].value"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </label> -->
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="font-weight-bold d-flex mb-1"
              >
                <!-- <b-button
                  variant="primary"
                  size="md"
                  @click="handleAddMoreInputMainPrize"
                >
                  + Add More Input Prize
                </b-button> -->
                <!-- <input
                  ref="fileInput"
                  type="file"
                  accept=".csv"
                  hidden
                  @change="handleFileUpload"
                >
                <b-button
                  variant="danger"
                  size="md"
                  class="ml-2"
                  @click="onClickInput"
                >
                  Import File
                </b-button> -->
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- MODAL ADD PRIZES -->
      <!-- <AddPrizeModal
        ref="pickPrize"
        type-pick="front"
        :data="formatListFrontPrizes"
        :is-edit="true"
      />
      <AddPrizeModal
        ref="pickPrizeBack"
        type-pick="back"
        :data="formatListBackPrizes"
        :is-edit="true"
      /> -->

      <b-modal
        ref="add-main-prize"
        :title="
          modalType === 'add' ? 'Add Main Prize' : modalType === 'update' ? 'Update Main Prize' : 'Delete Main Prize'
        "
        centered
        cancel-variant="outline-secondary"
        @ok="handleSubmitAddMainPrize"
        @hide="hideModal"
      >
        <div
          v-if="modalType === 'delete'"
          style="color: black"
        >
          Are you sure you want to delete this prize?
        </div>
        <div v-else>
          <div
            v-if="itemPrizeChange"
            class="d-flex justify-content-between mb-1"
          >
            <span
              class="d-block"
              style="color: black"
            > {{ toCardinal(itemPrizeChange.order) }} Prize</span>
          </div>
          <div
            v-if="!itemPrizeChange"
            class="d-flex justify-content-between mb-1"
          >
            <span
              class="d-block"
              style="color: black"
            > {{ toCardinal(mainPrizes.length + 1) }} Prize</span>
          </div>
          <!-- <validation-provider #default="{ errors }" name="prize" rules="required"> -->
          <b-form-input
            v-model="newMainPrize"
            :formatter="formatNumber"
          />
          <small
            v-if="errorNewMainPrize"
            class="text-danger"
          >{{ errorNewMainPrize }}</small>
          <!-- </validation-provider> -->
        </div>
        <template #modal-ok>
          <Spinner v-if="loadingAddEntry" />
          <span v-else>Save changes</span>
        </template>
      </b-modal>

      <b-modal
        ref="add-sport-leg"
        hide-footer
        title="Choose event"
        centered
        cancel-variant="outline-secondary"
        size="xl"
        @hide="handleHideModal"
      >
        <b-row class="text-modal">
          <b-col cols="12">
            <ListEvent
              :list-detail-event-prop="listDetailEventProp"
              :selected-sport="selectedSport"
              @create-leg="handleSelectEvent"
            />
          </b-col>
        </b-row>
      </b-modal>

      <!-- MODAL DETAIL EVENT -->
      <b-modal
        ref="detail-event"
        title="List Market"
        centered
        cancel-variant="outline-secondary"
        hide-footer
        size="xl"
      >
        <SelectDetaiEvent
          :event-id="eventIdSelected"
          :markets="listMarketSelected"
          @update-market="handleAddMarket"
        />
        <template #modal-ok>
          <span>Save</span>
        </template>
      </b-modal>

      <!-- MODAL REVIEW LIST PRIZES -->
      <b-modal
        ref="list-review"
        hide-footer
        title="Review list"
        centered
        cancel-variant="outline-secondary"
        size="lg"
      >
        <b-col cols="12">
          <b-row>
            <b-col
              v-for="(item, index) in reviewListPrize"
              :key="index"
              cols="12"
              md="3"
              class="mb-1"
            >
              <!-- <label for="" > -->

              <div class="d-flex justify-content-between mb-1">
                <span
                  class="d-block"
                  style="color: black;"
                > {{ toCardinal(index + 1) }} Prize</span>
              </div>
              <validation-provider
                #default="{ errors }"
                :name="toCardinal(index + 1) + ' prize'"
                rules="required"
              >
                <b-form-input
                  v-model="reviewListPrize[index]"
                  :formatter="formatNumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <!-- </label> -->
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>

        <p
          class="text-danger"
          style="padding-left: 12px; font-weight: bold;"
        >
          * The old prizes list will be deleted and replaced with the new list after you click save.
        </p>

        <b-row class="mt-2 mb-4">
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSavePrizeList"
            >
              <Spinner v-if="loadingAddEntry" />
              <template v-else>
                Save changes
              </template>
            </b-button>

          </b-col>
        </b-row>

      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  // BMedia,
  // BMediaAside,
  // BMediaBody,
  BImg,
  BFormFile,
  BFormRadioGroup,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useToastHandler from '@/services/toastHandler'
import { mapState, mapActions } from 'vuex'
import { objectProp } from '@/helpers/props'
import flatPickr from 'vue-flatpickr-component'
import { subDays, subHours, subSeconds } from 'date-fns'
import {
  formatDate, formatDateSend, formatDateTimeLocal, toCardinal, formatCurrencyBuiltInFunc, formatTime, formatMarketAlias,
} from '@/helpers/utils'
import { VueEditor } from 'vue2-editor'
import Spinner from '@core/components/Spinner'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { parse } from 'csv-parse'
import ListEvent from '../create-sport-comp/ListEvent.vue'
import SelectDetaiEvent from '../create-sport-comp/SelectDetaiEvent.vue'

export default {
  components: {
    flatPickr,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    VueEditor,
    Spinner,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    BImg,
    BFormFile,
    BFormRadioGroup,
    BSpinner,

    ListEvent,
    BDropdown,
    BDropdownItem,

    SelectDetaiEvent,
  },
  props: {
    comp: objectProp(),
  },
  data() {
    return {
      toCardinal,
      errorFrontPrizes: false,
      errorBackPrizes: false,
      compName: this.comp.name,
      openDate: new Date(this.comp.openForEntry),
      competitionDate: new Date(this.comp.competitionDate),
      endDate: new Date(this.comp.endDate),
      moreInfo: this.comp.moreInfo,
      remember: this.comp.remember,
      scratchings: this.comp.scratchings,
      percentWinner: this.comp.percentWinner,
      participantsDes: this.comp.participantDescriptions,
      latePrice: this.comp.latePrice,
      priceTo: this.comp.price,
      guestName: this.comp.guestName,

      morningLockout: new Date(this.comp.morningLockout),
      afternoonLockout: new Date(this.comp.afternoonLockout),

      disableDate: subDays(new Date(), 1),
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
      ],
      frontEntryList: [
        {
          legId: 1,
          type: 'front',
          title: 'LEG 1',
          value: '',
        },
        {
          legId: 2,
          type: 'front',
          title: 'LEG 2',
          value: '',
        },
        {
          legId: 3,
          type: 'front',
          title: 'LEG 3',
          value: '',
        },
        {
          legId: 4,
          type: 'front',
          title: 'LEG 4',
          value: '',
        },
        {
          legId: 5,
          type: 'front',
          title: 'LEG 5',
          value: '',
        },
        {
          legId: 6,
          type: 'front',
          title: 'LEG 6',
          value: '',
        },
        {
          legId: 7,
          type: 'front',
          title: 'LEG 7',
          value: '',
        },
        {
          legId: 8,
          type: 'front',
          title: 'LEG 8',
          value: '',
        },
      ],

      // webImage: this.comp.bannerUrl,
      // fileImage: this.comp.bannerUrl,
      // mobileImage: this.comp.bannerMobileUrl,
      // fileMobileImage: this.comp.bannerMobileUrl,

      // errorBannerWeb: false,
      // errorBannerMobile: false,

      selectedPrizeFrom: this.comp.prizeMode,

      optionsPrizeFrom: [
        { text: 'API', value: 'api' },
        { text: 'Manual', value: 'manual' },
      ],

      frontPrizes: [],
      backPrizes: [],
      mainPrizes: [],
      newMainPrize: null,
      itemPrizeChange: null,
      itemPrizeDelete: null,

      modalType: 'add',

      errorNewMainPrize: '',

      optionsFlexi: this.comp.flexiValues || [],

      optionsFlexiRollOverPool: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      selectedFlexiRollOverPool: this.comp.useFlexiRollOverWallet,
      apiPrice: this.comp.apiPrice ? this.comp.apiPrice : null,

      flexiRollOverAmount: this.comp.flexiRollOverAmount || 0,
      errorFlexiRollOverAmount: false,
      limitBetslip: this.comp.joinTimesLimit || null,
      manualBetSlipEntry: this.comp.manualBetSlipEntry || null,

      optionsSport: [
        {
          text: 'AFL',
          value: 'AFL',
        },
        {
          text: 'NRL',
          value: 'NRL',
        },
        // {
        //   text: 'NBA',
        //   value: 'NBA',
        // },
        // {
        //   text: 'BBL',
        //   value: 'BBL',
        // },
        // {
        //   text: 'NFL',
        //   value: 'NFL',
        // },
      ],
      selectedSport: this.comp.leagues.length > 0 ? this.comp.leagues[0].sportLeagueId : 'AFL',

      listDetailEventProp: [],
      loadingListMarket: false,
      listMarketEvent: [],

      loadingUpdateListEvent: false,

      originMarket: [],

      selectedTransferPrize: this.comp.prizeType,
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],
      flagSelected: null,
      subFlagSelected: null,
      lockLegFirstData: this.comp.lockoutLeg,
      legList: [],
      lockLeg: null,

      eventIdSelected: null,

      listMarketSelected: [],

      optionsUI: [
        { text: '1 Line', value: 'line' },
        { text: '1 column', value: 'list' },
        { text: '2 column', value: 'column' },
        { text: '1 column no flag', value: 'one-column-no-flag' },
      ],
      imageMarketSelected: null,
      reviewListPrize: [],
    }
  },

  computed: {
    ...mapState('races/createRace', [
      'loading',
      'listFormatFrontPrizes',
      'listFormatBackPrizes',
      'imageWebUrl',
      'imageMobileUrl',
    ]),
    ...mapState('races/listRace', ['listEventPicked']),
    ...mapState('races/compDetail', ['dataDetail', 'originEvent']),
    ...mapState('races/updateComp', ['loadingUpdateComp', 'addEntryId', 'loadingAddEntry']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
    // formatListFrontPrizes() {
    //   const listFront = this.comp.entryPrizes.filter(i => i.entryType === 0)
    //   return listFront.map(item => item.value)
    // },
    // formatListBackPrizes() {
    //   const listBack = this.comp.entryPrizes.filter(i => i.entryType === 1)
    //   return listBack.map(item => item.value)
    // },
    frontPrizeValue() {
      if (!this.frontPrizes) return ''
      const list = this.frontPrizes.map(i => i.value)
      return list.join(', ')
    },
    backPrizeValue() {
      if (!this.backPrizes) return ''
      const list = this.backPrizes.map(i => i.value)
      return list.join(', ')
    },
    disableChangeMode() {
      if (this.dataDetail.prizeMode === 'api') return
      // eslint-disable-next-line no-unused-expressions
      new Date(this.dataDetail.morningLockout) < new Date()

      return subHours(new Date(this.dataDetail.morningLockout), 1) < new Date()
    },
  },
  watch: {
    // imageWebUrl(val) {
    //   if (val) {
    //     this.webImage = val
    //     this.errorFrontPrizes = false
    //   }
    // },
    // imageMobileUrl(val) {
    //   if (val) {
    //     this.mobileImage = val
    //     this.errorBannerMobile = false
    //   }
    // },
    async dataDetail(val) {
      // const entryPrizes = val.entryPrizes
      // this.frontPrizes = entryPrizes.filter(i => i.entryType === 0)
      // this.backPrizes = entryPrizes.filter(i => i.entryType === 1)
      this.mainPrizes = val.prizes
      await this.getListMarket()
    },
    newMainPrize(val) {
      if (val) this.errorNewMainPrize = ''
    },
    flexiRollOverAmount(val) {
      if (val && this.selectedFlexiRollOverPool) this.errorFlexiRollOverAmount = false
    },
    selectedFlexiRollOverPool(val) {
      if (!val) {
        this.errorFlexiRollOverAmount = false
      }
    },
    listMarketEvent(val) {
      if (val.length === 0) {
        this.frontEntryList = this.frontEntryList.map(i => ({
          ...i,
          value: '',
        }))
        return
      }
      const formatList = val.map(event => {
        const listMarkets = event.markets.map(market => ({
          marketType: market.market.marketType,
          maxSelect: market.selectionLimit,
          marketAlias: market.market.marketAlias,
          marketLayout: market.market.marketLayout,
          name: market.market.name,
          selections: market.market.selections,
          startTime: event.startTime,
          marketId: market.marketId,
          isFeatured: market.isFeatured,
          displayName: market.displayName,
          ...(market.imageUrl && { imageUrl: market.imageUrl }),
          externalID: market.marketId,
        }))
        return {
          ...event,
          brandLinks: {
            ladbrokes: event.brandLinksLadbrokes,
            neds: event.brandLinksNeds,
          },
          markets: listMarkets,
          featuredMarket: listMarkets.find(i => i.isFeatured),
        }
      })
      const formatListFront = formatList

      // eslint-disable-next-line no-unused-vars
      const newListFront = this.frontEntryList.map((front, ind) => {
        // eslint-disable-next-line no-unused-vars
        const item = formatListFront.find((event, index) => front.legId === event.orderNumber)
        if (item) {
          return {
            ...front,
            value: {
              ...item,
              eventID: item.eventId,
              scores: item.scores.sort((a, b) => {
                if (a.role === 'HomeTeam') return -1
                if (b.role === 'HomeTeam') return 1
                return 0
              }),
            },
          }
        }
        return front
      })
      this.frontEntryList = newListFront

      // eslint-disable-next-line no-unused-vars
      const newEntriesFormatProp = formatList.map((i, ind) => {
        let number
        if (i.entryType === 0) number = i.orderNumber
        return {
          ...i,
          number,
          eventID: i.eventId,
        }
      })
      this.listDetailEventProp = newEntriesFormatProp
    },
    frontEntryList(val) {
      const list = val.filter(i => i.value)
      this.legList = list.map(i => ({
        number: i.legId, value: i.title,
      }))
    },
  },
  async mounted() {
    this.resetListPrizes()
    this.mainPrizes = this.dataDetail.prizes
    await this.getFlexiRollOverWallet()
    await this.getListMarket()
  },
  methods: {
    ...mapActions({
      resetListPrizes: 'races/createRace/reset',
      updateComp: 'races/updateComp/updateCompAsync',
      uploadImage: 'races/createRace/uploadImage',
      uploadImageMobile: 'races/createRace/uploadImageMobile',
      setPickListEvent: 'races/listRace/setPickListEvent',
      deleteEntry: 'races/updateComp/deleteEntry',
      updatePrize: 'races/updateComp/addPrize',
      getCompDetail: 'races/compDetail/getCompDetail',
      deletePrize: 'races/updateComp/deletePrize',
      updatePrizeMode: 'races/updateComp/updatePrizeMode',
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
      getListMarketOfSportComp: 'sportComp/createComp/getListMarketOfSportComp',

      removeSportEventsToComp: 'sportComp/updateComp/removeSportEventsToComp',
      handleAddSportEventsToComp: 'sportComp/createComp/addSportEventsToComp',
      handleAddSportMarketsToComp: 'sportComp/createComp/addSportMarketsToComp',
      updateLimitEventMarket: 'sportComp/updateComp/updateLimitEventMarket',

      removeLeagueToComp: 'sportComp/updateComp/removeLeagueToComp',
      addLeagueToComp: 'sportComp/createComp/addLeagueToComp',
      getDetailEvent: 'sportComp/eventDetail/getDetail',
      uploadFlag: 'sportComp/createComp/uploadFlag',
      changeFlagEvent: 'sportComp/updateComp/changeFlagEvent',
      updateSportEventsToComp: 'sportComp/updateComp/updateSportEventsToComp',
      deleteMarket: 'sportComp/updateComp/deleteMarket',
      handleAddLeagueToComp: 'sportComp/createComp/addLeagueToComp',
      updateMarket: 'sportComp/updateComp/updateMarket',
      deleteAllPrizes: 'races/updateComp/deleteAllPrizes',
    }),
    async getListMarket() {
      this.loadingListMarket = true
      const newListMarket = []
      const data = this.dataDetail.events
      if (data.length === 0) {
        this.originMarket = []
        this.loadingListMarket = false
        this.listMarketEvent = []
        return
      }
      for (let i = 0; i < data.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const res = await this.getListMarketOfSportComp({
          competitionId: this.$route.params.id,
          eventId: this.dataDetail.events[i].eventId,
        })
        const format = {
          markets: res,
          eventId: this.dataDetail.events[i].eventId,
        }
        newListMarket.push(format)
      }

      const formatData = data.map(event => {
        const item = newListMarket.find(x => x.eventId === event.eventId)
        return {
          ...event,
          markets: item.markets ?? [],
        }
      })

      this.originMarket = formatData
      this.loadingListMarket = false
      this.listMarketEvent = formatData
    },
    formatter(value) {
      let val = value.replace(/[^0-9.]/g, '')
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      if (parts[0].length > 3) {
        parts[0] = parts[0].slice(0, 3)
      }
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 0) {
        val = '0'
      } else if (numericVal > 100) {
        val = '100'
      }
      return val
    },
    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberFlexi(value) {
      const regexNumber = /^[0-9.]+$/

      if (Number(value) >= 100) return ''
      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    handleShowModalAddFrontPrizes() {
      this.$refs.pickPrize.openModal()
    },
    handleShowModalAddBackPrizes() {
      this.$refs.pickPrizeBack.openModal()
    },
    async handleUpdateComp() {
      const valid = await this.$refs.infoCompForm.validate()
      if (!valid) return

      // if (!this.webImage) {
      //   this.errorBannerWeb = true
      // }
      // if (!this.mobileImage) {
      //   this.errorBannerMobile = true
      // }
      // if (this.selectedFlexiRollOverPool && !this.apiPrice) this.errorAPIPrice = true
      if (this.selectedFlexiRollOverPool && !this.flexiRollOverAmount) this.errorFlexiRollOverAmount = true
      if (this.errorFlexiRollOverAmount) return

      const formatListFlexi = this.optionsFlexi.map(i => Number(i))

      const data = {
        name: this.compName,
        ...(this.guestName !== this.comp.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.participantsDes,
        remember: this.remember,
        scratchings: this.scratchings,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.comp.events.length === 0 ? this.formatDateSend(subSeconds(new Date(this.endDate), 40)) : this.formatDateSend(this.morningLockout),
        afternoonLockout: this.comp.events.length === 0 ? this.formatDateSend(subSeconds(new Date(this.endDate), 30)) : this.formatDateSend(this.afternoonLockout),
        // bannerUrl: this.webImage,
        // bannerMobileUrl: this.mobileImage,
        flexiValues: formatListFlexi,
        ...(new Date() <= new Date(this.comp.morningLockout) && {
          useFlexiRollOverWallet: this.selectedFlexiRollOverPool ? 1 : 0,
        }),
        ...(new Date() <= new Date(this.comp.morningLockout) && { apiPrice: Number(this.apiPrice) }),
        ...(new Date() <= new Date(this.comp.morningLockout)
          && this.selectedFlexiRollOverPool && { flexiRollOverAmount: Number(this.flexiRollOverAmount) }),
        ...(this.limitBetslip !== this.comp.joinTimesLimit && {
          joinTimesLimit: Number(this.limitBetslip),
        }),
        ...(this.manualBetSlipEntry !== this.comp.manualBetSlipEntry && {
          manualBetSlipEntry: Number(this.manualBetSlipEntry),
        }),
        prizeType: this.selectedTransferPrize,
        ...(this.lockLeg && this.comp.events.length > 0 && { lockoutLeg: this.lockLeg.value.startTime }),
        ...(this.comp.events.length === 0 && { lockoutLeg: this.formatDateSend(subSeconds(new Date(this.endDate), 30)) }),
      }

      const payload = { data, competitionId: this.$route.params.id }
      const res = await this.updateComp(payload)
      if (res) {
        this.showToastSuccess('Success', 'Update this competition successfully!')
        this.$router.push({ name: 'comp-list' })
      }
    },
    resetData() {
      this.compName = this.comp.name
      this.openDate = new Date(this.comp.openForEntry)
      this.competitionDate = new Date(this.comp.competitionDate)
      this.endDate = new Date(this.comp.endDate)
      this.moreInfo = this.comp.moreInfo
      this.remember = this.comp.remember
      this.scratchings = this.comp.scratchings
      this.percentWinner = this.comp.percentWinner
      this.participantsDes = this.comp.participantDescriptions
      this.latePrice = this.comp.latePrice
      this.priceTo = this.comp.price

      this.morningLockout = new Date(this.comp.morningLockout)
      this.afternoonLockout = new Date(this.comp.afternoonLockout)

      //   this.frontEntryList = this.frontEntries
      //   this.backEntryList = this.backEntries
      // this.webImage = this.comp.bannerUrl
      // this.mobileImage = this.comp.bannerMobileUrl
      // this.fileImage = this.comp.bannerUrl
      // this.fileMobileImage = this.comp.bannerMobileUrl
      this.optionsFlexi = this.comp.flexiValues
      this.selectedFlexiRollOverPool = this.comp.selectedFlexiRollOverPool
      this.limitBetslip = this.comp.joinTimesLimit
      this.manualBetSlipEntry = this.comp.manualBetSlipEntry
      this.selectedTransferPrize = this.comp.prizeType

      this.lockLeg = null
    },
    // async inputImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     useInputImageRenderer(evt.target, base64 => {
    //       this.fileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImage(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeImageRenderer() {
    //   this.webImage = this.comp.bannerUrl
    //   this.fileImage = this.comp.bannerUrl
    //   this.webImage = null
    // },
    // async inputMobileImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileMobileImage = file
    //     useInputImageRenderer(evt.target, base64 => {
    //       this.fileMobileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImageMobile(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeMobileImageRenderer() {
    //   this.mobileImage = this.comp.bannerMobileUrl
    //   this.fileMobileImage = this.comp.bannerMobileUrl
    // },
    handleHideModal() {
      this.$refs['add-sport-leg'].hide()
    },
    handleCreateLeg() {
      this.$refs['add-sport-leg'].show()
    },
    handleAddMoreInputMainPrize() {
      this.$refs['add-main-prize'].show()
      // this.mainPrizes.push('')
    },
    handleRemoveMoreInputMainPrize(item) {
      this.modalType = 'update'
      this.itemPrizeChange = item
      this.newMainPrize = item.value
      this.$refs['add-main-prize'].show()
      // this.mainPrizes.pop()
    },
    async handleSubmitAddMainPrize(event) {
      if (this.modalType === 'delete') {
        const res = await this.deletePrize({
          competitionId: this.$route.params.id,
          data: {
            'prizes[0][prizeId]': this.itemPrizeDelete.prizeId,
            'prizes[0][type]': 'main',
          },
        })
        if (res) {
          this.showToastSuccess('Success', 'Delete this main prize successfully!')
          this.mainPrizes.pop()
        }
        this.itemPrizeDelete = null
        this.modalType = 'add'
        return
      }
      event.preventDefault()
      if (!this.newMainPrize) return (this.errorNewMainPrize = 'This field is required')
      const res = await this.updatePrize({
        competitionId: this.$route.params.id,
        data: {
          prizes: [
            {
              order: this.itemPrizeChange ? this.itemPrizeChange.order : this.mainPrizes.length + 1,
              value: Number(this.newMainPrize),
              type: 'main',
            },
          ],
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Update main prize successfully!')
        this.getCompDetail(this.$route.params.id)
      } else {
        this.mainPrizes.pop()
      }
      this.hideModal()
    },
    async handleDeletePrize(item) {
      this.modalType = 'delete'
      this.itemPrizeDelete = item
      this.$refs['add-main-prize'].show()
    },
    hideModal() {
      this.newMainPrize = null
      this.itemPrizeChange = null
      this.itemPrizeDelete = null
      this.modalType = 'add'
      this.$refs['add-main-prize'].hide()
    },
    async handleUpdateGetPrizeFrom() {
      const res = await this.updatePrizeMode({
        competitionId: this.$route.params.id,
        data: {
          prizeMode: this.selectedPrizeFrom,
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Update prize mode  successfully!')
        this.getCompDetail(this.$route.params.id)
      }
    },
    handleAddMoreOptionFlexi() {
      this.optionsFlexi.push('')
    },
    handleRemoveOptionFlexi() {
      this.optionsFlexi.pop()
    },
    formatPriceRollOver(value) {
      if (value < 0) return null
      if (value > this.maxValueFlexiRollOver()) return this.maxValueFlexiRollOver()
      return value
    },
    maxValueFlexiRollOver() {
      if (!this.comp.flexiRollOverAmount) return this.flexiRollOverWallet
      return this.comp.flexiRollOverAmount + this.flexiRollOverWallet
    },
    checkDisableSelectSport() {
      return new Date() >= new Date(this.comp.openForEntry) && this.comp.status !== 'draft'
    },
    handleSelectEvent(data) {
      this.listDetailEventProp = data
      this.frontEntryList = this.frontEntryList.map(i => {
        const item = data.find(dataItem => dataItem.number === i.legId)
        if (!item) {
          return {
            ...i,
            value: '',
          }
        }
        const formatItem = {
          ...item,
          stadium: item.stadium ? item.stadium : item.venue,
          scores: item.scores,
          markets: item.markets.map(h => {
            if (!i.value.markets) return h
            const checkItem = i.value.markets.find(y => y.name === h.name)
            return {
              ...h,
              maxSelect: checkItem && checkItem.maxSelect ? checkItem.maxSelect : null,
              marketAlias: checkItem && checkItem.marketAlias ? checkItem.marketAlias : formatMarketAlias(checkItem.name),
              marketLayout: checkItem && checkItem.marketLayout ? checkItem.marketLayout : 'list',
            }
          }),
        }
        return {
          ...i,
          value: formatItem,
        }
      })
      // this.backEntryList = this.backEntryList.map(i => {
      //   const item = data.find(dataItem => dataItem.number == i.legId)
      //   if (!item)
      //     return {
      //       ...i,
      //       value: '',
      //     }
      //   const formatItem = {
      //     ...item,
      //     stadium: item.stadium ?  item.stadium : item.venue,
      //     scores: item.scores,
      //     markets: item.markets.map(h => {
      //       if (!i.value.markets) return h
      //       const checkItem = i.value.markets.find(y => y.name === h.name)
      //       return {
      //         ...h,
      //         maxSelect: checkItem && checkItem.maxSelect ? checkItem.maxSelect : null,
      //       }
      //     }),
      //   }
      //   return {
      //     ...i,
      //     value: formatItem,
      //   }
      // })

      this.handleHideModal()
    },
    async handleRemoveLeg(item) {
      const value = item.value
      const findEvent = this.dataDetail.events.find(i => i.eventId === value.eventId || i.eventID === value.eventID)
      if (!findEvent) {
        this.listDetailEventProp = this.listDetailEventProp.filter(
          event => (event.eventId && event.eventId !== value.eventId) || (event.eventID && event.eventID !== value.eventID),
        )
        if (item.type === 'front') {
          this.frontEntryList = this.frontEntryList.map(leg => {
            if (leg.legId === item.legId) {
              return {
                ...leg,
                value: '',
              }
            }
            return leg
          })
        }
        return
      }
      const res = await this.removeSportEventsToComp({
        competitionId: this.$route.params.id,
        eventId: value.eventId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Remove this event successfully!')
        await this.getCompDetail(this.$route.params.id)
        this.dataDetail.events = this.dataDetail.events.filter(i => i.eventId !== value.eventId)
        this.listDetailEventProp = this.listDetailEventProp.filter(i => i.eventId !== value.eventId)
        this.listMarketEvent = this.listMarketEvent.filter(i => i.eventId !== value.eventId)
        if (item.type === 'front') {
          this.frontEntryList = this.frontEntryList.map(i => {
            if (item.legId === i.legId) {
              return {
                ...i,
                value: '',
              }
            }
            return i
          })
        }
        // if (item.type === 'back')
        //   this.backEntryList = this.backEntryList.map(i => {
        //     if (item.legId === i.legId)
        //       return {
        //         ...i,
        //         value: '',
        //       }
        //     return i
        //   })
      }
    },
    async handleUpdateListEvent() {
      const list = this.frontEntryList
      const formatListHasLeg = list.filter(i => i.value)
      const checkErr = formatListHasLeg.find(
        i => i.value.markets.some(market => !market.maxSelect)
          || !i.value.stadium
          || i.value.scores.some(role => !role.flag)
          || !i.value.featuredMarket
        ,
      )
      if (checkErr && this.listDetailEventProp.length > 0) return this.showToastError('Please add completely detail event', 'Error')

      const getEventUpdate = formatListHasLeg.filter(
        event => !this.dataDetail.events.some(i => i.eventId === event.value.eventId),
      )
      // const getEventUpdate = itemUpdate.value
      if (getEventUpdate.length > 0) {
        const formatListLeg = []
        // eslint-disable-next-line no-unused-vars
        getEventUpdate.forEach((leg, ind) => {
          if (leg) {
            const dataItemFormat = leg.value
            const value = {
              eventId: dataItemFormat.eventID,
              eventTitle: dataItemFormat.eventTitle,
              startTime: dataItemFormat.startTime,
              sport: dataItemFormat.sport,
              country: dataItemFormat.country,
              league: dataItemFormat.league,
              primaryMarketName: dataItemFormat.primaryMarketName ?? '',
              displayStatus: dataItemFormat.displayStatus,
              bettingStatus: dataItemFormat.bettingStatus,
              detailLink: dataItemFormat.detailLink ?? '',
              brandLinksLadbrokes: dataItemFormat.brandLinks.ladbrokes,
              brandLinksNeds: dataItemFormat.brandLinks.neds,
              eventType: dataItemFormat.eventType,
              entryType: 0,
              orderNumber: dataItemFormat.number,
              stadium: dataItemFormat.stadium,
              scores: dataItemFormat.scores,
            }
            formatListLeg.push(value)
          }
        })

        // const checkAFL = formatListLeg?.some(i => i.league === 'AFL') && this.comp.leagues.some(i => i.sportLeagueId !== 'AFL')
        // const checkNRL = formatListLeg?.some(i => i.league === 'NRL') && this.comp.leagues.some(i => i.sportLeagueId !== 'NRL')
        // const checkNBA = formatListLeg?.some(i => i.league === 'NBA')
        // const checkBBL = formatListLeg?.some(i => i.league === 'BBL')
        // const checkNFL = formatListLeg?.some(i => i.league === 'NFL')

        if (!this.comp.events.length && ((this.comp.leagues[0] && this.comp.leagues[0].sportLeagueId !== getEventUpdate[0].value.league) || this.comp.leagues.league === 0)) {
          await this.removeLeagueToComp({
            competitionId: this.$route.params.id,
            leagueId: this.comp.leagues[0].sportLeagueId,
          })
        }

        if ((this.comp.leagues[0] && this.comp.leagues[0].sportLeagueId !== getEventUpdate[0].value.league) || this.comp.leagues.length === 0) {
          await this.handleAddLeagueToComp({
            competitionId: this.$route.params.id,
            sportLeagueId: getEventUpdate[0].value.league,
          })
        }

        // if (checkAFL) {
        //   await this.handleAddLeagueToComp({
        //     competitionId: this.$route.params.id,
        //     sportLeagueId: 'AFL',
        //   })
        // }
        // if (checkNRL) {
        //   await this.handleAddLeagueToComp({
        //     competitionId: this.$route.params.id,
        //     sportLeagueId: 'NRL',
        //   })
        // }
        // if (checkNBA) {
        //   await this.handleAddLeagueToComp({
        //     competitionId: this.$route.params.id,
        //     sportLeagueId: 'NBA',
        //   })
        // }
        // if (checkBBL) {
        //   await this.handleAddLeagueToComp({
        //     competitionId: this.$route.params.id,
        //     sportLeagueId: 'BBL',
        //   })
        // }
        // if (checkNFL) {
        //   await this.handleAddLeagueToComp({
        //     competitionId: this.$route.params.id,
        //     sportLeagueId: 'NFL',
        //   })
        // }

        const resEvent = await this.handleAddSportEventsToComp({
          competitionId: this.$route.params.id,
          events: formatListLeg,
        })
        if (resEvent) {
          await Promise.all([
            getEventUpdate.forEach(leg => {
              const featuredMarket = leg.value.featuredMarket
              const listMarkets = leg.value.markets.map((market, ind) => {
                const formatSelectionList = market.selections.map(sel => ({
                  name: sel.name,
                  role: sel.role,
                  winPrice: sel.winPrice,
                  marketSelectionId: sel.externalID,
                  ...(sel.handicap && { handicap: sel.handicap }),
                }))
                return {
                  name: market.name,
                  startTime: market.startTime,
                  marketType: market.marketType,
                  selectionLimit: market.maxSelect,
                  marketAlias: market.marketAlias,
                  selections: formatSelectionList,
                  marketId: market.externalID,
                  orderNumber: ind + 1,
                  ...(market.handicap && { handicap: market.handicap }),
                  marketLayout: market.marketLayout,
                  isFeatured: market.externalID === featuredMarket.externalID,
                  ...(market.externalID === featuredMarket.externalID && { displayName: featuredMarket.displayName }),
                  ...(market.imageUrl && { imageUrl: market.imageUrl }),
                }
              })
              this.handleAddSportMarketsToComp({
                competitionId: this.$route.params.id,
                eventId: leg.value.eventID,
                markets: listMarkets,
              })
            }),
          ])

          this.showToastSuccess('Success', 'Update list event successfully!')
          this.getCompDetail(this.$route.params.id)
        }
      }
    },
    async handleChangeLimitSelectedMarket(item, i) {
      const res = await this.updateLimitEventMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: i.marketId,
        selectionLimit: i.maxSelect,
      })

      if (res) {
        this.showToastSuccess('Success', `Update selection limit of market ${i.name} successfully!`)
        this.originMarket = this.originMarket.map(event => {
          if (res.eventId === event.eventId) {
            const list = event.markets.map(x => {
              if (x.marketId === res.marketId) {
                return {
                  ...x,
                  selectionLimit: res.selectionLimit,
                }
              }
              return x
            })
            return {
              ...event,
              markets: list,
            }
          }
          return event
        })
      }
    },

    async handleChangeMarketLayout(item, i) {
      const res = await this.updateLimitEventMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: i.marketId,
        marketLayout: i.marketLayout,
      })
      if (res) {
        this.showToastSuccess('Success', `Update selection limit of market ${i.name} successfully!`)
        this.originMarket = this.originMarket.map(event => {
          if (res.eventId === event.eventId) {
            const list = event.markets.map(x => {
              if (x.marketId === res.marketId) {
                return {
                  ...x,
                  market: {
                    ...x.market,
                    marketLayout: i.marketLayout,
                  },

                }
              }
              return x
            })
            return {
              ...event,
              markets: list,
            }
          }
          return event
        })
      }
    },

    async handleChangeMarketAlias(item, i) {
      const res = await this.updateLimitEventMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: i.marketId,
        marketAlias: i.marketAlias,
      })

      if (res) {
        this.showToastSuccess('Success', `Update selection limit of market ${i.name} successfully!`)
        this.originMarket = this.originMarket.map(event => {
          if (res.eventId === event.eventId) {
            const list = event.markets.map(x => {
              if (x.marketId === res.marketId) {
                return {
                  ...x,
                  market: {
                    ...x.market,
                    marketAlias: res.marketAlias,
                  },
                }
              }
              return x
            })
            return {
              ...event,
              markets: list,
            }
          }
          return event
        })
      }
    },

    async handleChangeImageMarket(item, i) {
      const res = await this.updateLimitEventMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: i.marketId,
        imageUrl: i.imageUrl,
      })

      if (res) {
        this.showToastSuccess('Success', `Update image of market ${i.name} successfully!`)
        this.originMarket = this.originMarket.map(event => {
          if (res.eventId === event.eventId) {
            const list = event.markets.map(x => {
              if (x.marketId === res.marketId) {
                return {
                  ...x,
                  imageUrl: i.imageUrl,
                }
              }
              return x
            })
            return {
              ...event,
              markets: list,
            }
          }
          return event
        })
      }
    },
    checkIsChangeLimitMarket(item, i) {
      const marketItem = this.originMarket.find(market => item.value.eventId === market.eventId)
      if (!marketItem) return false
      const marketData = marketItem.markets.find(y => y.marketId === i.marketId)
      return (Number(marketData.selectionLimit) !== Number(i.maxSelect) && !!Number(i.maxSelect))
    },
    checkIsChangeMarketAlias(item, i) {
      const marketItem = this.originMarket.find(market => item.value.eventId === market.eventId)
      if (!marketItem) return false
      const marketData = marketItem.markets.find(y => y.marketId === i.marketId)
      return marketData.market.marketAlias !== i.marketAlias && i.marketAlias !== ''
    },
    checkIsChangeMarketLayout(item, i) {
      const marketItem = this.originMarket.find(market => item.value.eventId === market.eventId)
      if (!marketItem) return false
      const marketData = marketItem.markets.find(y => y.marketId === i.marketId)
      return (marketData.market.marketLayout) !== (i.marketLayout)
    },

    checkIsChangeTeamAlias(item, role) {
      // eslint-disable-next-line no-shadow
      const ev = this.originEvent.find(event => event.eventId === item.eventId)
      if (!ev) return false
      const listRoles = ev.scores.find(i => i.role === role)
      const listRoleOfItem = item.scores.find(i => i.role === role)

      return listRoles.teamAlias !== listRoleOfItem.teamAlias
    },
    checkIsChangeImageMarket(item, i) {
      const marketItem = this.originMarket.find(market => item.value.eventId === market.eventId)
      if (!marketItem) return false
      const marketData = marketItem.markets.find(y => y.marketId === i.marketId)
      return ((marketData.imageUrl) !== (i.imageUrl) && !!(i.imageUrl))
    },

    async handleChangeteamAlias(item) {
      const res = await this.changeFlagEvent({
        eventId: item.eventID,
        data: {
          scores: item.scores,
        },
      })
      if (res) {
        this.showToastSuccess('Success', `Change teamAlias of event ${item.eventTitle} successfully!`)
        await this.getCompDetail(this.$route.params.id)
      }
    },

    checkChangeSport() {
      return this.selectedSport !== this.comp.leagues[0].sportLeagueId
    },
    // async handleChangeSport() {
    //   const resRemoveLeague = await this.removeLeagueToComp({
    //     competitionId: this.$route.params.id,
    //     leagueId: this.comp.leagues[0].sportLeagueId,
    //   })
    //   if (resRemoveLeague) {
    //     const resAddLeague = await this.addLeagueToComp({
    //       competitionId: this.$route.params.id,
    //       sportLeagueId: this.selectedSport,
    //     })
    //     if (resAddLeague) {
    //       this.showToastSuccess('Success', 'Change sport successfully!')
    //       await this.getCompDetail(this.$route.params.id)
    //     }
    //   }
    // },
    async handleUpdateMarket() {
      await this.getCompDetail(this.$route.params.id)
    },
    async inputImageRendererFlag(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => {})
        formData.append('image', file)
        const res = await this.uploadFlag(formData)

        if (res) {
          if (this.flagSelected && this.flagSelected.type === 'front') {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.flagSelected.eventID === leg.value.eventID) {
                const scores = leg.value.scores.map(i => {
                  if (this.flagSelected.role === i.role) {
                    return {
                      ...i,
                      flag: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    scores,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    async inputImageRendererSubFlag(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => { })
        formData.append('image', file)
        const res = await this.uploadFlag(formData)

        if (res) {
          if (this.subFlagSelected && this.subFlagSelected.type === 'front') {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.subFlagSelected.eventID === leg.value.eventID) {
                const scores = leg.value.scores.map(i => {
                  if (this.subFlagSelected.role === i.role) {
                    return {
                      ...i,
                      subFlag: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    scores,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    handleClick(item, role, type) {
      const eventID = item.value.eventID
      const formatItem = {
        type,
        eventID,
        role: role.role,
      }
      this.flagSelected = formatItem
      const element = this.$refs[`${eventID}-${role.role}-flag`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },
    handleClickSubFlag(item, role, type) {
      const eventID = item.value.eventID
      const formatItem = {
        type,
        eventID,
        role: role.role,
      }
      this.subFlagSelected = formatItem
      const element = this.$refs[`${eventID}-${role.role}-subflag`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },
    checkChange(arrA, arrB) {
      // eslint-disable-next-line no-restricted-syntax
      for (const itemA of arrA) {
        const correspondingItemB = arrB.find(itemB => itemB.role === itemA.role)
        if (correspondingItemB && (itemA.flag !== correspondingItemB.flag || itemA.subFlag !== correspondingItemB.subFlag)) {
          return true
        }
      }
      return false
    },
    checkShowIconChangeFlag(item) {
      // eslint-disable-next-line no-shadow
      const event = this.dataDetail.events.find(event => event.eventId === item.eventId)
      if (!event) return false
      const listRoles = event.scores
      const listRoleOfItem = item.scores

      return this.checkChange(listRoles, listRoleOfItem)
    },
    async handleChangeFlag(item) {
      const res = await this.changeFlagEvent({
        eventId: item.eventID,
        data: {
          scores: item.scores,
        },
      })
      if (res) {
        this.showToastSuccess('Success', `Update flag of event ${item.eventTitle} successfully!`)
        await this.getCompDetail(this.$route.params.id)
      }
    },
    handleSelectLeg(i) {
      const findItem = this.frontEntryList.find(fr => fr.legId === i.number)
      this.lockLeg = findItem
    },
    handleShowModalDetail(item) {
      this.eventIdSelected = item.eventID
      this.$refs['detail-event'].show()
      this.listMarketSelected = item.markets
    },
    handleAddMarket(data) {
      // const newData = {
      //   ...data[0], selectionLimit: 1,
      // }
      this.$refs['detail-event'].hide()
      if (data.length > 0) {
        const formatList = data.map(i => ({
          ...i,
          marketAlias: formatMarketAlias(i.name),
          maxSelect: 1,
          marketLayout: 'list',
        }))
        this.frontEntryList = this.frontEntryList.map(i => {
          if (i.value.eventID === this.eventIdSelected) {
            return {

              ...i,
              value: {
                ...i.value,
                markets: [...i.value.markets, ...formatList],
              },
            }
          }
          return i
        })
      }
    },
    handleRemoveMarket(x, item) {
      this.frontEntryList = this.frontEntryList.map(i => {
        if (i.value.eventID === item.value.eventID) {
          return {
            ...i,
            value: {
              ...i.value,
              markets: i.value.markets.filter(market => market.externalID !== x.externalID),
            },
          }
        }
        return i
      })
    },
    checkShowIconRemove(item) {
      return !this.dataDetail.events.some(i => i.eventId === item.value.eventID)
    },

    checkRemoveMarKet(item) {
      return this.dataDetail.events.some(i => i.eventId === item.value.eventID)
    },
    async  handleRemoveOldMarket(x, item) {
      const res = await this.deleteMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: x.marketId,
      })
      if (res) {
        this.showToastSuccess('Success', 'Remove this market successfully!')
        await this.getCompDetail(this.$route.params.id)
      }
    },
    handleApplyAllMarket() {
      const listMarketFirstEvent = this.frontEntryList?.[0].value.markets

      const newList = this.frontEntryList
        .map(async i => {
          if (!i.value || i.legId === 1) return i
          const list = []
          const res = await this.getDetailEvent(i.value.eventID)
          const getListMarket = res.markets
          listMarketFirstEvent.forEach(market => {
            const findItem = getListMarket.find(ma => ma.name === market.name)
            if (findItem) {
              list.push({
                ...findItem,
                maxSelect: market.maxSelect,
                marketAlias: formatMarketAlias(market.name),
                marketLayout: market.marketLayout,
              })
              i.value.markets = list
            }
          })
          return i
        })

      Promise.all(newList).then(result => {
        this.frontEntryList = result
        this.showToastSuccess('Success', 'Auto pick complete all events successfully!')
      })
    },
    handleSelectFeatureMarket(i, item) {
      this.frontEntryList = this.frontEntryList.map(entry => {
        if (entry.value.eventID === item.value.eventID) {
          return {
            ...entry,
            value: {
              ...entry.value,
              featuredMarket: {
                ...i, displayName: '',
              },
            },
          }
        }
        return entry
      })
    },
    checkChangeFeatureMarket(item) {
      const event = this.originMarket.find(market => item.value.eventId === market.eventId)
      if (!event) return false
      const market = event.markets.find(i => i.isFeatured)
      return item.value.featuredMarket.displayName !== market.displayName || item.value.featuredMarket.marketId !== market.marketId
    },
    async handleSaveChangeFeaturedMarket(item) {
      const event = this.originMarket.find(market => item.value.eventId === market.eventId)
      const market = event.markets.find(i => i.isFeatured)
      await this.updateMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: market.marketId,
        displayName: '',
        isFeatured: false,
      })
      const featuredMarket = item.value.featuredMarket
      const res = await this.updateMarket({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        marketId: featuredMarket.marketId,
        displayName: featuredMarket.displayName,
        isFeatured: true,
      })
      if (res) {
        this.showToastSuccess('Success', `Update featured market of event ${item.eventTitle} successfully!`)
        await this.getCompDetail(this.$route.params.id)
      }
    },
    checkIsChangeStadium(item) {
      // eslint-disable-next-line no-shadow
      const event = this.originEvent.find(event => event.eventId === item.value.eventId)
      if (!event) return false
      return event.stadium !== item.value.stadium
    },
    async handleChangeStadium(item) {
      const res = await this.updateSportEventsToComp({
        competitionId: this.$route.params.id,
        eventId: item.value.eventId,
        stadium: item.value.stadium,
      })
      if (res) {
        this.showToastSuccess('Success', `Update featured market of event ${item.eventTitle} successfully!`)
        await this.getCompDetail(this.$route.params.id)
      }
    },
    handleUploadImage(item, market) {
      const eventID = item.value.eventID
      const formatItem = {
        marketId: market.externalID,
        eventID,
      }
      this.imageMarketSelected = formatItem
      const element = this.$refs[`${eventID}-${market.externalID}-image`][0]
      if (element) {
        // eslint-disable-next-line no-unused-expressions
        element?.$el?.click()
      }
    },

    async inputImageRendererImageMarket(evt) {
      const formData = new FormData()
      const file = evt.target.files[0]
      if (file && file.size <= 10 * 1024 * 1024) {
        // eslint-disable-next-line no-unused-vars
        useInputImageRenderer(evt.target, base64 => { })
        formData.append('image', file)
        const res = await this.uploadFlag(formData)
        if (res) {
          if (this.imageMarketSelected) {
            this.frontEntryList = this.frontEntryList.map(leg => {
              if (this.imageMarketSelected.eventID === leg.value.eventID) {
                const markets = leg.value.markets.map(i => {
                  if (this.imageMarketSelected.marketId === i.externalID) {
                    return {
                      ...i,
                      imageUrl: res,
                    }
                  }
                  return i
                })
                return {
                  ...leg,
                  value: {
                    ...leg.value,
                    markets,
                  },
                }
              }
              return leg
            })
          }
        }
      } else {
        this.showToastError('Error', 'The file is less than or equal to 10MB')
      }
    },
    onClickInput() {
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
      this._resetInputFile()
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => i[1])
        this.reviewListPrize = newList
        if (newList) {
          this.$refs['list-review'].show()
          //  list-review
        }
      })
    },
    _resetInputFile() {
      this.$refs.fileInput.value = null
    },
    async handleSavePrizeList() {
      const formatList = this.reviewListPrize.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'main',
      }))
      await this.deleteAllPrizes({
        competitionId: this.$route.params.id,
        type: 'main',
      })
      const res = await this.updatePrize({
        competitionId: this.$route.params.id,
        data: {
          prizes: formatList,
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Update main prize successfully!')
        this.getCompDetail(this.$route.params.id)
        this.$refs['list-review'].hide()
        this.reviewListPrize = []
      }
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
      formatDate,
      formatDateSend,
      formatCurrencyBuiltInFunc,
      formatDateTimeLocal,
      formatTime,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.banner-img {
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-modal {
  color: black !important;
}

.banner-img {
  max-width: 100%;
}

.add-btn {
  padding: 2px 4px;
}

.remove-btn {
  padding: 0px 4px 2px 4px;
  margin-left: 1px;
}

.delete-btn {
  color: red;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.text-modal {
  color: black;
}

.modal.modal-footer {
  display: none !important;
}
</style>
