<template>
  <div>
    <LoadingPage v-if="loading" />

    <b-card v-else>

      <UpdateComp
        v-if="dataDetail.type === 'classic'"
        :comp="dataDetail"
        :front-entries="formatFrontEntries"
        :back-entries="formatBackEntries"
        class="mt-2 pt-75"
      />
      <UpdateSportComp
        v-if="dataDetail.type === 'sport'"
        :comp="dataDetail"
        class="mt-2 pt-75"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingPage from '@core/components/LoadingPage'
import {
  BCard,
} from 'bootstrap-vue'
import { ENTRY_TYPE_BACK, ENTRY_TYPE_FRONT } from '@/@core/utils/enum/comp'
import { formatDateTimeLocal } from '@/helpers/utils'
import UpdateComp from './UpdateComp'
import UpdateSportComp from '../../comp-sport/update-comp/UpdateSportComp.vue'

export default {
  components: {
    BCard,
    UpdateComp,
    LoadingPage,
    UpdateSportComp,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      formatDateTimeLocal,

    }
  },
  computed: {
    ...mapState({
      // dataCompDetail: s => s.races.compDetail.dataDetail,
    }),
    ...mapState('races/compDetail', ['dataDetail']),
    formatFrontEntries() {
      if (this.dataDetail.type === 'sport') return []

      const list = [
        { legId: 1, title: 'LEG 1', value: '' },
        { legId: 2, title: 'LEG 2', value: '' },
        { legId: 3, title: 'LEG 3', value: '' },
        { legId: 4, title: 'LEG 4', value: '' },
        { legId: 5, title: 'LEG 5', value: '' },
        { legId: 6, title: 'LEG 6', value: '' },
        { legId: 7, title: 'LEG 7', value: '' },
      ]
      let newList = []
      const frontEntries = this.dataDetail.legs.filter(item => item.entryType === ENTRY_TYPE_FRONT)

      if (frontEntries[0] && frontEntries[0].orderNumber) {
        newList = list.map(i => {
          const frontEntry = frontEntries.find(item => item.entryType === ENTRY_TYPE_FRONT && item.orderNumber === i.legId)
          if (frontEntry) {
            return {
              ...i,
              ...frontEntry,
              name: frontEntry.raceName,
              value: frontEntry.meetingName ? `${frontEntry.meetingName} R${frontEntry.raceNumber}, ${formatDateTimeLocal(frontEntry.startTime)}` : frontEntry.raceName,
              type: i.legId,
              id: frontEntry.raceId,
              meetingName: frontEntry.meetingName,
              raceNumber: frontEntry.raceNumber,
            }
          }
          return i
        })
      } else {
        newList = frontEntries.map((item, ind) => ({
          ...item,
          name: item.raceName,
          value: item.meetingName ? `${item.meetingName} R${item.raceNumber}, ${formatDateTimeLocal(item.startTime)}` : item.raceName,
          legId: ind + 1,
          title: `LEG ${ind + 1}`,
          type: ind + 1,
          id: item.raceId,
          meetingName: item.meetingName,
          raceNumber: item.raceNumber,
        }))
        const length = newList.length

        if (newList.length < 7) {
          const itemsToAdd = 7 - newList.length

          for (let i = 1; i <= itemsToAdd; i++) {
            const newLegId = length + i
            const newTitle = `LEG ${newLegId}`
            const newItem = { legId: newLegId, title: newTitle, value: '' }
            newList.push(newItem)
          }
        }
      }

      return newList
    },
    formatBackEntries() {
      if (this.dataDetail.type === 'sport') return []
      const list = [
        { legId: 1, title: 'LEG 1', value: '' },
        { legId: 2, title: 'LEG 2', value: '' },
        { legId: 3, title: 'LEG 3', value: '' },
        { legId: 4, title: 'LEG 4', value: '' },
        { legId: 5, title: 'LEG 5', value: '' },
        { legId: 6, title: 'LEG 6', value: '' },
        { legId: 7, title: 'LEG 7', value: '' },
      ]
      let newList = []

      const backEntries = this.dataDetail.legs.filter(item => item.entryType === ENTRY_TYPE_BACK)

      if (backEntries[0] && backEntries[0].orderNumber) {
        newList = list.map(i => {
          const backEntry = backEntries.find(item => item.entryType === ENTRY_TYPE_BACK && item.orderNumber === i.legId + 7)
          if (backEntry) {
            return {
              ...backEntry,
              ...i,
              name: backEntry.raceName,
              value: backEntry.meetingName ? `${backEntry.meetingName} R${backEntry.raceNumber}, ${formatDateTimeLocal(backEntry.startTime)}` : backEntry.raceName,
              type: i.legId + 7,
              id: backEntry.raceId,
              meetingName: backEntry.meetingName,
              raceNumber: backEntry.raceNumber,
            }
          }
          return i
        })
      } else {
        newList = backEntries.map((item, ind) => ({
          ...item,
          name: item.raceName,
          value: item.meetingName ? `${item.meetingName} R${item.raceNumber}, ${formatDateTimeLocal(item.startTime)}` : item.raceName,
          legId: ind + 1,
          title: `LEG ${ind + 1}`,
          type: ind + 1 + 7,
          id: item.raceId,
          meetingName: item.meetingName,
          raceNumber: item.raceNumber,
        }))
        if (newList.length < 7) {
          const itemsToAdd = 7 - newList.length
          const length = newList.length

          for (let i = 1; i <= itemsToAdd; i++) {
            const newLegId = length + i
            const newTitle = `LEG ${newLegId}`
            const newItem = { legId: newLegId, title: newTitle, value: '' }
            newList.push(newItem)
          }
        }
      }

      return newList
    },

  },
  watch: {
    // loadingDeleteEntry(val) {
    //   c
    // }
  },
  async created() {
    this.setPickEvent()
    this.setPickRace()
    this.loading = true
    await this.getCompDetail(this.$route.params.id)
    this.setPickListEvent([...this.formatFrontEntries, ...this.formatBackEntries])
    this.loading = false
    this.setEntryAddId(null)
  },
  methods: {
    ...mapActions({
      getCompDetail: 'races/compDetail/getCompDetail',
      setPickEvent: 'races/listRace/setPickEvent',
      setPickRace: 'races/listRace/setPickRace',
      setPickListEvent: 'races/listRace/setPickListEvent',
      setEntryAddId: 'races/updateComp/setEntryAddId',
    }),
  },
  setup() {
    return {
      ENTRY_TYPE_FRONT,
      ENTRY_TYPE_BACK,
      formatDateTimeLocal,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .active-title {
    color: white;
  }
  </style>
