<!-- eslint-disable vue/no-template-shadow -->
<template>
  <section style="color: black !important;">

    <b-card>
      <b-row class="d-flex align-items-center justify-content-between mt-2 mb-2">
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end ">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search market..."
            />
          </div>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="handleAddMarkets"
          >
            <template>
              Add markets
            </template>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="refDetailEvent"
        :items="listMarkets"
        :fields="tableColumns"
        responsive
        primary-key="market"
        show-empty
      >
        <template #empty>

          <div
            v-if="listMarkets.length === 0"
            class="text-center my-2"
          >
            No matching records found
          </div>
        </template>

        <!-- startTime -->
        <template #cell(startTime)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ formatTime(item.startTime, 'HH:mm aa dd-MM-yyyy') }}
            </div>
          </b-media>
        </template>
        <!-- selected -->
        <template #cell(selected)="{ item }">
          <b-media vertical-align="center">
            <b-form-checkbox
              v-model="selectedMarkets"
              name="event-filter"
              :value="item"
              class="mb-1 ml-2"
              checked="true"
              :disabled="disableSelectMarket(item)"
            >
              {{ item.username }}
            </b-form-checkbox>
          </b-media>
        </template>
        <!-- SELECTIONS -->
        <template #cell(selections)="{ item }">
          <b-media vertical-align="center">
            <div class="d-flex align-items-center font-weight-bold">
              {{ item.selections.length }}
            </div>
          </b-media>
        </template>

      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BTable,
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BMedia,
  BFormCheckbox,
} from 'bootstrap-vue'

import { mapActions, mapState } from 'vuex'
import useToastHandler from '@/services/toastHandler'
import { arrayProp, stringProp } from '@/helpers/props'
import { computed, ref, watch } from '@vue/composition-api'
import { formatTime } from '@/helpers/utils'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BFormCheckbox,
  },
  props: {
    eventId: stringProp(),
    markets: arrayProp(),
  },

  data() {
    return {
      typeSelections: [
        { text: 'sport', value: 'sport' },
        { text: 'draw', value: 'draw' },
      ],
      selectedType: 'sport',
      result: '',
      errorResult: '',
      winPrice: null,
      errorWinPrice: '',

      selectedSelection: null,
      selectedMarket: null,

      listMarkets: [],
      timeout: null,
      originalList: [],
      selectedMarkets: [],

    }
  },
  computed: {
    ...mapState('sportComp/eventDetail', ['loading', 'loadingChangeSelection']),
    searchQuery: {
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.listMarkets = this.originalList.filter(i => i.name.toLowerCase().includes(val.toLowerCase()))
        }, 500)
      },
    },
  },
  watch: {
    winPrice(val) {
      if (val) this.errorWinPrice = ''
    },
    result(val) {
      if (val) this.errorResult = ''
    },
  },
  async created() {
    await this.fetchDetailEvent()
  },
  methods: {
    ...mapActions({
      getListMarketEventDetail: 'sportComp/eventDetail/getListMarketEventDetail',
      changeSelection: 'sportComp/eventDetail/changeSelection',
      getDetail: 'sportComp/eventDetail/getDetail',
    }),
    async fetchDetailEvent() {
      const data = await this.getDetail(this.eventId)
      this.totalMarket = data.markets.length
      this.listMarkets = data.markets
      this.originalList = data.markets
      return data.markets
    },
    disableSelectMarket(item) {
      return this.markets.some(i => i.externalID === item.externalID)
    },
    handleAddMarkets() {
      this.$emit('update-market', this.selectedMarkets)
    },
  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    const perPage = ref(10)
    const refDetailEvent = ref(null)
    const currentPage = ref(1)
    const totalMarket = ref(0)

    const tableColumns = [
      { key: 'selected' },
      {
        key: 'externalID', label: 'marketId',
      },
      {
        key: 'marketType',
      },
      {
        key: 'name',
      },
      {
        key: 'handicap',
      },
      { key: 'startTime' },
      { key: 'selections', label: 'total selection' },
    ]

    const refetchData = () => {
      refDetailEvent.value.refresh()
    }

    watch([currentPage, perPage], () => {
      refetchData()
    })

    const dataMeta = computed(() => {
      const localItemsCount = refDetailEvent.value ? refDetailEvent.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalMarket.value,
      }
    })

    return {
      refDetailEvent,

      showToastSuccess,
      showToastError,
      perPage,
      currentPage,

      refetchData,
      dataMeta,

      tableColumns,

      formatTime,
    }
  },
}
</script>
