<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon icon="BookIcon" size="19" />
          <h4 class="mb-0 ml-50">UPDATE COMP</h4>
        </div>

        <validation-observer ref="infoCompForm">
          <!-- Form: Information Form -->
          <b-form class="mt-1">
            <b-row>
              <!-- COMP NAME -->
              <b-col cols="12" md="6">
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (logged in)</label>
                  </div>
                  <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
                    <b-form-input v-model="compName" type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- COMP NAME -->
              <b-col cols="12" md="6">
                <b-form-group>
                  <div class="d-flex">
                    <label for="name">NAME (not logged in)</label>
                  </div>
                  <b-form-input v-model="guestName" type="text" />
                </b-form-group>
              </b-col>

              <!-- Showdown Open Date/Time -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="openDate">Showdown Open Date/Time</label>
                  </div>
                  <validation-provider name="openDate" vid="openDate">
                    <flat-pickr id="openDate" v-model="openDate" class="form-control" :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                      // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                    }" />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- First Leg Start Date/Time -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="competitionDate">First Leg Start Date/Time</label>
                  </div>
                  <validation-provider name="competitionDate" vid="competitionDate">
                    <flat-pickr id="competitionDate" v-model="competitionDate" class="form-control" :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                      // disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                    }" />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--  END DATE/TIME -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="END DATE/TIME">END DATE/TIME</label>
                  </div>
                  <validation-provider name="endTime" vid="endTime">
                    <flat-pickr id="endDate" v-model="endDate" class="form-control" :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:s',
                      enableSeconds: true,
                      disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                    }" />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--MORE INFO -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="more info">MORE INFO</label>
                  </div>
                  <validation-provider #default="{ errors }" name="moreInfo" vid="moreInfo" rules="required">
                    <b-form-input v-model="moreInfo" type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--REMEMBER -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="remember">REMEMBER</label>
                  </div>
                  <validation-provider #default="{ errors }" name="remember" vid="remember" rules="required">
                    <b-form-input v-model="remember" type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--SCRATCHINGS -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="scratchings">SCRATCHINGS</label>
                  </div>
                  <validation-provider #default="{ errors }" name="scratchings" vid="scratchings" rules="required">
                    <b-form-input v-model="scratchings" type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- PERCENT WINNER -->
              <!-- <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="percent winner">PERCENT WINNER</label>
                  </div>
                  <validation-provider name="percentWinner" vid="percentWinner">
                    <b-form-input v-model="percentWinner" :formatter="formatter" />
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <!-- ENTRY FEE -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="entry fee">ENTRY FEE</label>
                  </div>
                  <validation-provider #default="{ errors }" name="priceTo" vid="priceTo" rules="required">
                    <b-form-input v-model="priceTo" type="number" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- RE BUY FEE -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">RE BUY FEE</label>
                  </div>
                  <validation-provider #default="{ errors }" name="latePrice" vid="latePrice" rules="required">
                    <b-form-input v-model="latePrice" type="number" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- LIMIT REAL BETSLIP -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">LIMIT BETSLIP</label>
                  </div>
                  <validation-provider #default="{ errors }" name="limit betslip" vid="limit betslip" rules="required">
                    <b-form-input id="limit-value" v-model="limitBetslip" :formatter="formatNumberBetslip" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- MANUAL BETSLIP -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">MANUAL BETSLIP ENTRY (optional)</label>
                  </div>
                  <b-form-input id="limit-value" v-model="manualBetSlipEntry" :formatter="formatNumberBetslip" />
                </b-form-group>
              </b-col>

              <!-- Api Price -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group>
                  <div class="d-flex">
                    <label for="re buy fee">BONUS ADDITIONAL TICKET (optional)</label>
                  </div>
                  <b-form-input v-model="apiPrice" min="0" type="number" :no-wheel="true"
                    placeholder="Enter 100 for 0.5 tickets @ $50" />
                </b-form-group>
              </b-col>

              <!-- COMP DESCRIPTIONS -->
              <!-- <b-col cols="12">
                <b-form-group>
                  <div class="d-flex">
                    <label for="descriptions">DESCRIPTIONS</label>
                  </div>
                  <validation-provider name="compDes" vid="compDes">
                    <b-form-textarea id="textarea" v-model="compDes" rows="3" />
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              <!--PARTICIPANTS DESCRIPTIONS -->
              <b-col cols="12">
                <b-form-group>
                  <div class="d-flex">
                    <label for="participants descriptions">PARTICIPANTS DESCRIPTIONS</label>
                  </div>
                  <validation-provider #default="{ errors }" name="participantsDes" vid="participantsDes"
                    rules="required|limitCharacters">
                    <vue-editor v-model="participantsDes" name="participantsDes" :editor-toolbar="customToolbar" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" class="font-weight-bold d-flex justify-content-between mb-1">
                <span>FLEXI OPTION</span>
                <b-button variant="primary" size="md" @click="handleAddMoreOptionFlexi"> + Add More </b-button>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12" md="4" v-for="(item, index) in optionsFlexi" :key="index" class="mb-1">
                    <div class="d-flex justify-content-between mb-1">
                      <span class="d-block"> {{ toCardinal(index + 1) }} Option (%)</span>
                      <b-button variant="danger" size="sm" @click="handleRemoveOptionFlexi"> x </b-button>
                    </div>
                    <validation-provider #default="{ errors }" :name="toCardinal(index + 1) + ' Option'"
                      rules="required">
                      <b-form-input v-model="optionsFlexi[index]" :formatter="formatNumberFlexi" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <!-- </label> -->
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!--DESCRIPTION INFO -->
              <!-- <b-col cols="12">
                <b-form-group>
                  <div class="d-flex">
                    <label for="description info">DESCRIPTION INFO</label>
                  </div>
                  <validation-provider name="desInfo" vid="desInfo">
                    <b-form-textarea id="textarea-info" v-model="desInfo" rows="3" />
                  </validation-provider>
                </b-form-group>
              </b-col> -->
            </b-row>

            <!-- BANNER WEB -->
            <!-- <b-row>
              <b-media no-body class="d-flex flex-column">
                <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                  <h6 class="font-weight-bold mr-75">BANNER WEBSITE</h6>
                  <b-button E variant="primary" size="sm" class="mr-75" @click="$refs.refInputEl.$el.click()">
                    Upload
                  </b-button>

                  <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .heic, .jpeg" :hidden="true" plain
                    @change="inputImageRenderer" />

                  <b-button variant="outline-secondary" size="sm" class="mr-75" @click="removeImageRenderer">
                    Reset
                  </b-button>
                </b-media-body>
                <b-media-aside>
                  <b-img class="banner-img ml-75" v-if="fileImage" :src="fileImage" alt="logo" />
                </b-media-aside>
              </b-media>
            </b-row>
            <small v-if="errorBannerWeb" class="text-danger mb-75">Please upload banner image</small> -->

            <!-- BANNER MOBILE -->
            <!-- <b-row>
              <b-media no-body class="d-flex flex-column">
                <b-media-body class="mt-75 d-flex align-items-center mb-75 ml-75">
                  <h6 class="font-weight-bold mr-75">BANNER MOBILE</h6>
                  <b-button E variant="primary" size="sm" class="mr-75" @click="$refs.refInputMobileEl.$el.click()">
                    Upload
                  </b-button>

                  <b-form-file ref="refInputMobileEl" accept=".jpg, .png, .gif, .heic, .jpeg" :hidden="true" plain
                    @change="inputMobileImageRenderer" />

                  <b-button variant="outline-secondary" size="sm" class="mr-75" @click="removeMobileImageRenderer">
                    Reset
                  </b-button>
                </b-media-body>
                <b-media-aside>
                  <b-img class="banner-img ml-75" v-if="fileMobileImage" :src="fileMobileImage" alt="logo" />
                </b-media-aside>
              </b-media>
            </b-row>
            <small v-if="errorBannerMobile" class="text-danger mb-75">Please upload banner image</small> -->

            <!-- FLEXI ROLL OVER POOL -->
            <b-row class="mb-1 mt-2 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <h4 class="mb-0">Use Flexi Roll Over Pool</h4>
              </b-col>
              <b-col cols="9" style="display: flex; align-items: center">
                <b-form-radio-group :disabled="new Date() >= new Date(comp.morningLockout)" id="radio-inline-flexi"
                  v-model="selectedFlexiRollOverPool" :options="optionsFlexiRollOverPool"
                  name="radio-inline-flexi"></b-form-radio-group>

              </b-col>
            </b-row>

            <b-row>
              <template v-if="selectedFlexiRollOverPool">
                <div class="d-flex" style="gap: 10px">
                  <div style="display: flex; align-items: center; justify-content: center">
                    <span style="margin-right: 20px; margin-left: 10px; font-weight: bold">Roll Over Amount($)</span>
                    <b-form-input :disabled="new Date() >= new Date(comp.morningLockout)" v-model="flexiRollOverAmount"
                      min="0" type="number" :no-wheel="true" style="max-width: 100px;text-align: center;"
                      :formatter="formatPriceRollOver" />
                    <small style="margin-right: 20px; margin-left: 10px; font-weight: bold">Max: {{
                      formatCurrencyBuiltInFunc(maxValueFlexiRollOver()) }}</small>
                  </div>
                </div>
              </template>
            </b-row>
            <div class="d-flex" style="flex-direction: column">
              <!-- <small v-if="errorAPIPrice" class="text-danger mb-75">Ticket price is required</small> -->
              <small v-if="errorFlexiRollOverAmount" class="text-danger mb-75">Roll over amount is required</small>
            </div>

            <!-- PRIZE TYPE -->
            <b-row class="mb-1 mt-2 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <h4>Transfer Prize By </h4>
              </b-col>
              <b-col cols="9">
                <b-form-radio-group id="transfer-prize" v-model="selectedTransferPrize"
                  :options="optionsTransferPrizeBy" name="transfer-prize"></b-form-radio-group>
              </b-col>
            </b-row>

            <!-- JACKPOT -->
            <b-row class="mb-1 mt-2 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <h4>Enable Jackpot</h4>
              </b-col>
              <b-col cols="9">
                <b-form-radio-group id="jackpot" v-model="selectedJackpot" :options="optionsJackpot" name="jackpot" />
              </b-col>
            </b-row>

            <div class="d-flex my-2 align-items-center">
              <h4 class="mb-0">COMP LEGS</h4>
              <b-button v-if="dataDetail.legs.length > 0 && dataDetail.status === 'draft'" variant="primary" class="ml-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="handleRemoveAllLegs">
                <template> Remove all legs </template>
              </b-button>

            </div>


            <!-- LEG -->
            <b-row class="">
              <b-col cols="12" md="6">
                <h6 class="mb-1">MORNING LOCKOUT</h6>
                <flat-pickr id="morningLockout" v-model="morningLockout" class="form-control mb-1" :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:s',
                  enableSeconds: true,
                  static: true,
                }" />
                <ChooseLeg v-for="(item, ind) in frontEntryList" :id="`${ind}-front`" :key="ind" :title="item.title"
                  type="front" :value="item.value" :leg-number="ind + 1" @update-data="handleUpdateLegFront"
                  @delete-data="handleDeleteLegFront" @create-leg="handleCreateLeg" />
                <div v-if="frontEntryList.length < 7" class="d-flex justify-content-center">
                  <b-button variant="primary" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="addLegFront">
                    <template> New leg </template>
                  </b-button>
                </div>
                <!-- <small v-if="errorFrontLeg" class="text-danger">Please add front entries</small> -->
              </b-col>
              <b-col cols="12" md="6">
                <h6 class="mb-1">AFTERNOON LOCKOUT</h6>
                <flat-pickr id="afternoonLockout" v-model="afternoonLockout" class="form-control mb-1" :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:s',
                  enableSeconds: true,
                  static: true,
                }" />
                <ChooseLeg v-for="(item, ind) in backEntryList" :id="`${ind}-back`" :key="ind" :title="item.title"
                  :value="item.value" type="back" :leg-number="ind + 1" @update-data="handleUpdateLegBack"
                  @delete-data="handleDeleteLegBack" @create-leg="handleCreateLeg" />
                <div v-if="backEntryList.length < 7" class="d-flex justify-content-center">
                  <b-button variant="primary" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="addLegBack">
                    <template> New leg </template>
                  </b-button>
                </div>
                <!-- <small v-if="errorBackLeg" class="text-danger">Please add back entries</small> -->
              </b-col>
            </b-row>

            <!-- LEG -->

            <b-row class="mt-4">
              <b-col class="d-flex justify-content-end">
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="handleUpdateComp"
                  :disabled="loadingUpdateComp">
                  <Spinner v-if="loadingUpdateComp" />
                  <template v-else> Save changes </template>
                </b-button>
                <b-button variant="outline-secondary" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="resetData">
                  Reset
                </b-button>
              </b-col>
            </b-row>

            <!-- Header: Personal Info -->
            <div class="d-flex mt-4 mb-2">
              <feather-icon icon="BookIcon" size="19" />
              <h4 class="mb-0 ml-50">UPDATE PRIZE</h4>
            </div>

            <!-- Get Prize From -->
            <b-row class="mb-2 mt-1 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <span>Get Prize From </span>
              </b-col>
              <b-col cols="9" style="display: flex; gap: 4px; align-items: center">
                <b-form-radio-group v-model="selectedPrizeFrom" :options="optionsPrizeFrom" name="radio-inline"
                  :disabled="disableChangeMode"></b-form-radio-group>
                <b-button v-if="dataDetail.prizeMode !== selectedPrizeFrom" variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleUpdateGetPrizeFrom" :disabled="loadingAddEntry">
                  <Spinner v-if="loadingAddEntry" />
                  <template v-else> Save </template>
                </b-button>

              </b-col>
            </b-row>

            <!-- Is Show Front/Back-->
            <b-row v-if="selectedPrizeFrom === 'manual'" class="mb-1 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <span>Front/Back</span>
              </b-col>
              <b-col cols="9" class="d-flex align-items-center">
                <b-form-radio-group id="front-back" v-model="isShowFrontBack" :options="optionsShowFrontBack"
                  name="front-back"></b-form-radio-group>
                <b-button v-if="!isShowFrontBack && comp.entryPrizes.length > 0" variant="primary" class="mr-0 mr-sm-1 "
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="handleRemoveEntryPrize">
                  <Spinner v-if="loadingAddEntry" />
                  <template v-else> Save </template>
                </b-button>
              </b-col>
            </b-row>

            <!-- Front-Prize -->
            <b-row v-if="
              dataDetail.prizeMode === 'manual' && selectedPrizeFrom === 'manual' && !disableChangeMode && isShowFrontBack
            " class="mb-1 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <span>Front Prize</span>
              </b-col>
              <b-col cols="9">
                <b-form-input @click="handleShowModalAddFrontPrizes" :value="frontPrizeValue" readonly />
              </b-col>
            </b-row>

            <!-- Back-Prize -->
            <b-row v-if="
              dataDetail.prizeMode === 'manual' && selectedPrizeFrom === 'manual' && !disableChangeMode && isShowFrontBack
            " class="mb-1 d-flex align-items-start">
              <b-col cols="3" class="font-weight-bold">
                <span>Back Prize</span>
              </b-col>
              <b-col cols="9">
                <b-form-input @click="handleShowModalAddBackPrizes" :value="backPrizeValue" readonly />
              </b-col>
            </b-row>

            <!-- Main-Prize -->
            <b-row v-if="
              dataDetail.prizeMode === 'manual' && selectedPrizeFrom === 'manual' && !disableChangeMode
            " class="mb-1 d-flex align-items-start">
              <b-col cols="12" class="font-weight-bold d-flex align-items-center mb-1">
                <span>Main Prize</span>
                <input ref="fileInput" type="file" accept=".csv" hidden @change="handleFileUpload">
                <b-button v-if="dataDetail.prizeMode === selectedPrizeFrom && dataDetail.prizeMode === 'manual'"
                  variant="danger" size="md" class="ml-2" @click="onClickInput">
                  Import File
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12" md="4" v-for="(item, index) in mainPrizes" :key="index" class="mb-1">
                    <div class="d-flex justify-content-between mb-1">
                      <span class="d-block"> {{ toCardinal(index + 1) }} Prize</span>
                      <div>
                        <b-button variant="primary" style="
                            padding: 4px;
                            font-size: 10px;
                          " @click="handleRemoveMoreInputMainPrize(item)">
                          Edit
                        </b-button>
                        <b-button v-if="index === mainPrizes.length - 1" variant="gradient-danger" size="sm"
                          style="margin-left: 2px; padding: 2px !important; padding-right: 3px; padding-left: 3px"
                          @click="handleDeletePrize(item)">
                          x
                        </b-button>
                      </div>
                    </div>
                    <validation-provider #default="{ errors }" name="prize" rules="required">
                      <b-form-input v-model="mainPrizes[index].value" disabled />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- </label> -->
                  </b-col>
                </b-row>
              </b-col>
              <!-- <b-col cols="12" class="font-weight-bold d-flex mb-1">
                <b-button variant="primary" size="md" @click="handleAddMoreInputMainPrize">
                  + Add More Input Prize
                </b-button>
                <input
                  ref="fileInput"
                  type="file"
                  accept=".csv"
                  hidden
                  @change="handleFileUpload"
                >
                <b-button
                  v-if="mainPrizes.length === 0"
                  variant="danger"
                  size="md"
                  class="ml-2"
                  @click="onClickInput"
                >
                  Import File
                </b-button>
              </b-col> -->
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <!-- MODAL ADD PRIZES -->
      <AddPrizeModal ref="pickPrize" type-pick="front" :data="formatListFrontPrizes" :is-edit="true" />
      <AddPrizeModal ref="pickPrizeBack" type-pick="back" :data="formatListBackPrizes" :is-edit="true" />

      <b-modal ref="add-main-prize" :title="modalType === 'add' ? 'Add Main Prize' : modalType === 'update' ? 'Update Main Prize' : 'Delete Main Prize'
        " centered cancel-variant="outline-secondary" @ok="handleSubmitAddMainPrize" @hide="hideModal">
        <div v-if="modalType === 'delete'" style="color: black">Are you sure you want to delete this prize?</div>
        <div v-else>
          <div v-if="itemPrizeChange" class="d-flex justify-content-between mb-1">
            <span class="d-block" style="color: black"> {{ toCardinal(itemPrizeChange.order) }} Prize</span>
          </div>
          <div v-if="!itemPrizeChange" class="d-flex justify-content-between mb-1">
            <span class="d-block" style="color: black"> {{ toCardinal(mainPrizes.length + 1) }} Prize</span>
          </div>
          <!-- <validation-provider #default="{ errors }" name="prize" rules="required"> -->
          <b-form-input v-model="newMainPrize" :formatter="formatNumber" />
          <small v-if="errorNewMainPrize" class="text-danger">{{ errorNewMainPrize }}</small>
          <!-- </validation-provider> -->
        </div>
        <template #modal-ok>
          <Spinner v-if="loadingAddEntry" />
          <span v-else>Save changes</span>
        </template>
      </b-modal>

      <!-- MODAL REVIEW LIST PRIZES -->
      <b-modal ref="list-review" hide-footer title="Review list" centered cancel-variant="outline-secondary" size="lg">
        <b-col cols="12">
          <b-row>
            <b-col v-for="(item, index) in reviewListPrize" :key="index" cols="12" md="3" class="mb-1">
              <!-- <label for="" > -->

              <div class="d-flex justify-content-between mb-1">
                <span class="d-block" style="color: black;"> {{ toCardinal(index + 1) }} Prize</span>
              </div>
              <validation-provider #default="{ errors }" :name="toCardinal(index + 1) + ' prize'" rules="required">
                <b-form-input v-model="reviewListPrize[index]" :formatter="formatNumber" />
                <small class="text-danger">{{ errors[0] }}</small>
                <!-- </label> -->
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>

        <p class="text-danger" style="padding-left: 12px; font-weight: bold;">
          * The old prizes list will be deleted and replaced with the new list after you click save.
        </p>

        <b-row class="mt-2 mb-4">
          <b-col class="d-flex justify-content-end">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="handleSavePrizeList">
              <Spinner v-if="loadingAddEntry" />
              <template v-else>
                Save changes
              </template>
            </b-button>

          </b-col>
        </b-row>

      </b-modal>

      <b-modal ref="modal" title="Remove All Legs" centered cancel-variant="outline-secondary"
        :ok-disabled="loadingDeleteAllLegs" @ok="handleSubmitModal">
        <b-card-text  style="color: black">
          Are you sure you want to delete all legs?
        </b-card-text>
        <template #modal-ok>
          <Spinner v-if="loadingDeleteAllLegs" />
          <span v-else>Submit</span>
        </template>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BFormFile,
  BFormRadioGroup,
  BCardText,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useToastHandler from '@/services/toastHandler'
import { mapState, mapActions } from 'vuex'
import { objectProp, arrayProp } from '@/helpers/props'
import flatPickr from 'vue-flatpickr-component'
import { addHours, subDays, subHours } from 'date-fns'
import { formatDate, formatDateSend, formatDateTimeLocal, toCardinal, formatCurrencyBuiltInFunc } from '@/helpers/utils'
import { VueEditor } from 'vue2-editor'
import AddPrizeModal from '../create-comp/AddPrizeModal.vue'
import ChooseLeg from '../create-comp/ChooseLeg.vue'
import Spinner from '@core/components/Spinner'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { parse } from 'csv-parse'
import { data } from 'vue-echarts'

export default {
  components: {
    flatPickr,
    ChooseLeg,
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    VueEditor,
    AddPrizeModal,
    Spinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BFormFile,
    BFormRadioGroup,
    BCardText
  },
  props: {
    comp: objectProp(),
    frontEntries: arrayProp(),
    backEntries: arrayProp(),
  },
  data() {
    return {
      toCardinal,
      errorFrontPrizes: false,
      errorBackPrizes: false,
      compName: this.comp.name,
      openDate: new Date(this.comp.openForEntry),
      competitionDate: new Date(this.comp.competitionDate),
      endDate: new Date(this.comp.endDate),
      moreInfo: this.comp.moreInfo,
      remember: this.comp.remember,
      scratchings: this.comp.scratchings,
      percentWinner: this.comp.percentWinner,
      participantsDes: this.comp.participantDescriptions,
      latePrice: this.comp.latePrice,
      priceTo: this.comp.price,
      guestName: this.comp.guestName,

      morningLockout: new Date(this.comp.morningLockout),
      afternoonLockout: new Date(this.comp.afternoonLockout),

      disableDate: subDays(new Date(), 1),
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
      ],

      frontEntryList: this.frontEntries,
      backEntryList: this.backEntries,

      // webImage: this.comp.bannerUrl,
      // fileImage: this.comp.bannerUrl,
      // mobileImage: this.comp.bannerMobileUrl,
      // fileMobileImage: this.comp.bannerMobileUrl,

      // errorBannerWeb: false,
      // errorBannerMobile: false,

      selectedPrizeFrom: this.comp.prizeMode,

      optionsPrizeFrom: [
        { text: 'API', value: 'api' },
        { text: 'Manual', value: 'manual' },
      ],

      selectedTransferPrize: this.comp.prizeType,
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],

      frontPrizes: [],
      backPrizes: [],
      mainPrizes: [],
      newMainPrize: null,
      itemPrizeChange: null,
      itemPrizeDelete: null,

      modalType: 'add',

      errorNewMainPrize: '',

      optionsFlexi: this.comp.flexiValues || [],

      optionsFlexiRollOverPool: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      selectedFlexiRollOverPool: this.comp.useFlexiRollOverWallet,
      apiPrice: this.comp.apiPrice ? this.comp.apiPrice : null,
      // errorAPIPrice: false,

      flexiRollOverAmount: this.comp.flexiRollOverAmount || 0,
      errorFlexiRollOverAmount: false,
      limitBetslip: this.comp.joinTimesLimit || null,
      manualBetSlipEntry: this.comp.manualBetSlipEntry || null,

      optionsJackpot: [
        { text: 'No', value: false },
        { text: 'Yes', value: true },
      ],
      selectedJackpot: this.comp.isJackpotEnabled || false,
      reviewListPrize: [],

      optionsShowFrontBack: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      isShowFrontBack: this.comp.entryPrizes.length > 0,
      loadingDeleteAllLegs: false,

    }
  },

  computed: {
    ...mapState('races/createRace', [
      'loading',
      'listFormatFrontPrizes',
      'listFormatBackPrizes',
      'imageWebUrl',
      'imageMobileUrl',
    ]),
    ...mapState('races/listRace', ['listEventPicked']),
    ...mapState('races/compDetail', ['dataDetail']),
    ...mapState('races/updateComp', ['loadingUpdateComp', 'addEntryId', 'loadingAddEntry']),
    ...mapState('races/flexiRollOver', ['flexiRollOverWallet']),
    formatListFrontPrizes() {
      const listFront = this.comp.entryPrizes.filter(i => i.entryType === 0)
      return listFront.map(item => item.value)
    },
    formatListBackPrizes() {
      const listBack = this.comp.entryPrizes.filter(i => i.entryType === 1)
      return listBack.map(item => item.value)
    },
    frontPrizeValue() {
      if (!this.frontPrizes) return ''
      const list = this.frontPrizes.map(i => i.value)
      return list.join(', ')
    },
    backPrizeValue() {
      if (!this.backPrizes) return ''
      const list = this.backPrizes.map(i => i.value)
      return list.join(', ')
    },
    disableChangeMode() {
      if (this.dataDetail.prizeMode === 'api') return
      new Date(this.dataDetail.morningLockout) < new Date()

      return subHours(new Date(this.dataDetail.morningLockout), 1) < new Date()
    },
  },
  watch: {
    // imageWebUrl(val) {
    //   if (val) {
    //     this.webImage = val
    //     this.errorFrontPrizes = false
    //   }
    // },
    // imageMobileUrl(val) {
    //   if (val) {
    //     this.mobileImage = val
    //     this.errorBannerMobile = false
    //   }
    // },
    listEventPicked(val) {
      this.frontEntryList = val.slice(0, 7)
      this.backEntryList = val.slice(val.length - 7)
    },
    dataDetail(val) {
      const entryPrizes = val.entryPrizes
      this.frontPrizes = entryPrizes.filter(i => i.entryType === 0)
      this.backPrizes = entryPrizes.filter(i => i.entryType === 1)
      this.mainPrizes = val.prizes
    },
    newMainPrize(val) {
      if (val) this.errorNewMainPrize = ''
    },
    // apiPrice(val) {
    //   if (val && this.selectedFlexiRollOverPool) this.errorAPIPrice = false
    // },
    flexiRollOverAmount(val) {
      if (val && this.selectedFlexiRollOverPool) this.errorFlexiRollOverAmount = false
    },
    selectedFlexiRollOverPool(val) {
      if (!val) {
        // this.errorAPIPrice = false
        this.errorFlexiRollOverAmount = false
      }
    },
  },
  async mounted() {
    this.resetListPrizes()
    const entryPrizes = this.dataDetail.entryPrizes
    entryPrizes.forEach(i => {
      if (i.entryType === 0) this.frontPrizes.push(i)
      if (i.entryType === 1) this.backPrizes.push(i)
    })
    this.mainPrizes = this.dataDetail.prizes
    await this.getFlexiRollOverWallet()
  },
  methods: {
    ...mapActions({
      resetListPrizes: 'races/createRace/reset',
      updateComp: 'races/updateComp/updateCompAsync',
      uploadImage: 'races/createRace/uploadImage',
      uploadImageMobile: 'races/createRace/uploadImageMobile',
      setPickListEvent: 'races/listRace/setPickListEvent',
      deleteEntry: 'races/updateComp/deleteEntry',
      updatePrize: 'races/updateComp/addPrize',
      getCompDetail: 'races/compDetail/getCompDetail',
      deletePrize: 'races/updateComp/deletePrize',
      updatePrizeMode: 'races/updateComp/updatePrizeMode',
      getFlexiRollOverWallet: 'races/flexiRollOver/getFlexiRollOverWallet',
      deleteAllPrizes: 'races/updateComp/deleteAllPrizes',
    }),
    formatter(value) {
      let val = value.replace(/[^0-9.]/g, '')
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      if (parts[0].length > 3) {
        parts[0] = parts[0].slice(0, 3)
      }
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 0) {
        val = '0'
      } else if (numericVal > 100) {
        val = '100'
      }
      return val
    },
    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberFlexi(value) {
      const regexNumber = /^[0-9.]+$/

      if (Number(value) >= 100) return ''
      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },
    handleShowModalAddFrontPrizes() {
      this.$refs['pickPrize'].openModal()
    },
    handleShowModalAddBackPrizes() {
      this.$refs['pickPrizeBack'].openModal()
    },
    addLegFront() {
      const length = this.frontEntryList.length
      const item = { legId: length + 1, title: `LEG ${length + 1}`, value: '' }
      this.frontEntryList = [...this.frontEntryList, item]
    },
    addLegBack() {
      const length = this.backEntryList.length
      const item = { legId: length + 1, title: `LEG ${length + 1}`, value: '' }
      this.backEntryList = [...this.backEntryList, item]
    },
    handleUpdateLegFront(data) {
      if (data.type === 'add') {
        this.frontEntryList = this.frontEntryList.map(item => {
          if (data.legNumber === item.legId)
            return {
              ...item,
              value: `${data.raceName} R${data.event.number}, ${formatDateTimeLocal(data.event.startTime)}`,
              ...data.event,
              meetingName: data.raceName,
              meetingId: data.meetingId,
              raceNumber: data.event.number,
              entryType: 0,
              entryId: this.addEntryId,
            }
          return item
        })
      }
      if (data.type === 'remove') {
        this.frontEntryList = this.frontEntryList.map(item => {
          if (data.name === item.value) {
            if (this.$route.name === 'comp-update') this.deleteEntry(item.entryId)
            return { ...item, value: '' }
          }
          return item
        })
      }
    },
    handleUpdateLegBack(data) {
      if (data.type === 'add') {
        this.backEntryList = this.backEntryList.map(item => {
          if (data.legNumber === item.legId)
            return {
              ...item,
              value: `${data.raceName} R${data.event.number}, ${formatDateTimeLocal(data.event.startTime)}`,
              ...data.event,
              meetingName: data.raceName,
              meetingId: data.meetingId,
              raceNumber: data.event.number,
              entryType: 1,
              entryId: this.addEntryId,
            }
          return item
        })
      }
      if (data.type === 'remove') {
        this.backEntryList = this.backEntryList.map(item => {
          if (data.name === item.value) {
            if (this.$route.name === 'comp-update') this.deleteEntry(item.entryId)
            return { ...item, value: '' }
          }
          return item
        })
      }
    },
    handleDeleteLegFront(data) {
      const deleteItem = this.frontEntryList.find(item => item.legId === data.legNumber)
      if (deleteItem.value) {
        this.deleteEntry(deleteItem.entryId)
        // const newListPicked = this.listEventPicked.filter(item => item.value !== deleteItem.value)
        const newListPicked = this.listEventPicked.map(item => {
          if (item.value === deleteItem.value) return {
            ...item, value: ''
          }
          return item
        })

        this.setPickListEvent(newListPicked)
      }

      // const updatedData = this.frontEntryList.filter(item => item.legId !== data.legNumber)

      // const newData = updatedData.map((item, index) => ({
      //   ...item,
      //   legId: index + 1,
      //   title: `LEG ${index + 1}`,
      // }))
      // this.frontEntryList = newData
    },
    handleDeleteLegBack(data) {
      const deleteItem = this.backEntryList.find(item => item.legId === data.legNumber)
      if (deleteItem.value) {
        this.deleteEntry(deleteItem.entryId)
        // const newListPicked = this.listEventPicked.filter(item => item.value !== deleteItem.value)
        const newListPicked = this.listEventPicked.map(item => {
          if (item.value === deleteItem.value) return {
            ...item, value: ''
          }
          return item
        })
        this.setPickListEvent(newListPicked)
      }

      // const updatedData = this.backEntryList.filter(item => item.legId !== data.legNumber)

      // const newData = updatedData.map((item, index) => ({
      //   ...item,
      //   legId: index + 1,
      //   title: `LEG ${index + 1}`,
      // }))
      // this.backEntryList = newData
    },
    async handleUpdateComp() {
      const valid = await this.$refs.infoCompForm.validate()
      if (!valid) return

      // if (!this.webImage) {
      //   this.errorBannerWeb = true
      // }
      // if (!this.mobileImage) {
      //   this.errorBannerMobile = true
      // }
      // if (this.selectedFlexiRollOverPool && !this.apiPrice) this.errorAPIPrice = true
      if (this.selectedFlexiRollOverPool && !this.flexiRollOverAmount) this.errorFlexiRollOverAmount = true
      if (this.errorFlexiRollOverAmount) return

      const formatListFlexi = this.optionsFlexi.map(i => Number(i))

      const data = {
        name: this.compName,
        ...(this.guestName !== this.comp.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.participantsDes,
        remember: this.remember,
        scratchings: this.scratchings,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.formatDateSend(this.morningLockout),
        afternoonLockout: this.formatDateSend(this.afternoonLockout),
        // bannerUrl: this.webImage,
        // bannerMobileUrl: this.mobileImage,
        flexiValues: formatListFlexi,
        ...(new Date() <= new Date(this.comp.morningLockout) && {
          useFlexiRollOverWallet: this.selectedFlexiRollOverPool ? 1 : 0,
        }),
        ...(Number(this.flexiRollOverAmount) && !this.selectedFlexiRollOverPool && {
          flexiRollOverAmount: 0,
        }),
        ...(new Date() <= new Date(this.comp.morningLockout) && { apiPrice: Number(this.apiPrice) }),
        ...(new Date() <= new Date(this.comp.morningLockout) &&
          this.selectedFlexiRollOverPool && { flexiRollOverAmount: Number(this.flexiRollOverAmount) }),
        ...(this.limitBetslip !== this.comp.joinTimesLimit && {
          joinTimesLimit: Number(this.limitBetslip),
        }),
        ...(this.manualBetSlipEntry !== this.comp.manualBetSlipEntry && {
          manualBetSlipEntry: Number(this.manualBetSlipEntry),
        }),
        prizeType: this.selectedTransferPrize,
        lockoutLeg: this.formatDateSend(this.morningLockout),
        isJackpotEnabled: this.selectedJackpot,
      }

      const payload = { data, competitionId: this.$route.params.id }
      const res = await this.updateComp(payload)
      if (res) {
        this.showToastSuccess('Success', `Update this competition successfully!`)
        this.$router.push({ name: 'comp-list' })
      }
    },
    resetData() {
      this.errorFrontLeg = false
      this.errorBackLeg = false
      this.compName = this.comp.name
      this.openDate = new Date(this.comp.openForEntry)
      this.competitionDate = new Date(this.comp.competitionDate)
      this.endDate = new Date(this.comp.endDate)
      this.moreInfo = this.comp.moreInfo
      this.remember = this.comp.remember
      this.scratchings = this.comp.scratchings
      this.percentWinner = this.comp.percentWinner
      this.participantsDes = this.comp.participantDescriptions
      this.latePrice = this.comp.latePrice
      this.priceTo = this.comp.price

      this.morningLockout = new Date(this.comp.morningLockout)
      this.afternoonLockout = new Date(this.comp.afternoonLockout)

      this.frontEntryList = this.frontEntries
      this.backEntryList = this.backEntries
      // this.webImage = this.comp.bannerUrl
      // this.mobileImage = this.comp.bannerMobileUrl
      this.fileImage = this.comp.bannerUrl
      this.fileMobileImage = this.comp.bannerMobileUrl
      this.optionsFlexi = this.comp.flexiValues
      this.selectedFlexiRollOverPool = this.comp.selectedFlexiRollOverPool
      this.limitBetslip = this.comp.joinTimesLimit
      this.manualBetSlipEntry = this.comp.manualBetSlipEntry
      this.selectedTransferPrize = this.comp.prizeType
    },
    // async inputImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     useInputImageRenderer(evt.target, base64 => {
    //       this.fileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImage(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeImageRenderer() {
    //   this.webImage = this.comp.bannerUrl
    //   this.fileImage = this.comp.bannerUrl
    // },
    // async inputMobileImageRenderer(evt) {
    //   const formData = new FormData()
    //   const file = evt.target.files[0]
    //   if (file && file.size <= 10 * 1024 * 1024) {
    //     this.fileMobileImage = file
    //     useInputImageRenderer(evt.target, base64 => {
    //       this.fileMobileImage = base64
    //     })
    //     formData.append('image', file)
    //     await this.uploadImageMobile(formData)
    //   } else {
    //     this.showToastError('Error', 'The file is less than or equal to 10MB')
    //   }
    // },
    // removeMobileImageRenderer() {
    //   this.mobileImage = this.comp.bannerMobileUrl
    //   this.fileMobileImage = this.comp.bannerMobileUrl
    // },
    async handleCreateLeg() {
      this.frontEntryList = this.frontEntryList.map(item => {
        const checkItem = this.listEventPicked.find(i => i.type === item.legId)
        if (checkItem)
          return {
            ...item,
            ...checkItem,
            entryType: 0,
          }
        return item
      })
      this.backEntryList = this.backEntryList.map(item => {
        const checkItem = this.listEventPicked.find(i => i.type === item.legId + 7)
        if (checkItem)
          return {
            ...item,
            ...checkItem,
            entryType: 1,
          }
        return item
      })
        this.getCompDetail(this.$route.params.id)
    },
    handleAddMoreInputMainPrize() {
      this.$refs['add-main-prize'].show()
      // this.mainPrizes.push('')
    },
    handleRemoveMoreInputMainPrize(item) {
      this.modalType = 'update'
      this.itemPrizeChange = item
      this.newMainPrize = item.value
      this.$refs['add-main-prize'].show()
      // this.mainPrizes.pop()
    },
    async handleSubmitAddMainPrize(event) {
      if (this.modalType === 'delete') {
        const res = await this.deletePrize({
          competitionId: this.$route.params.id,
          data: {
            'prizes[0][prizeId]': this.itemPrizeDelete.prizeId,
            'prizes[0][type]': 'main',
          },
        })
        if (res) {
          this.showToastSuccess('Success', 'Delete this main prize successfully!')
          this.mainPrizes.pop()
        }
        this.itemPrizeDelete = null
        this.modalType = 'add'
        return
      }
      event.preventDefault()
      if (!this.newMainPrize) return (this.errorNewMainPrize = 'This field is required')
      const res = await this.updatePrize({
        competitionId: this.$route.params.id,
        data: {
          prizes: [
            {
              order: this.itemPrizeChange ? this.itemPrizeChange.order : this.mainPrizes.length + 1,
              value: Number(this.newMainPrize),
              type: 'main',
            },
          ],
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Update main prize successfully!')
        this.getCompDetail(this.$route.params.id)
      } else {
        this.mainPrizes.pop()
      }
      this.hideModal()
    },
    async handleDeletePrize(item) {
      this.modalType = 'delete'
      this.itemPrizeDelete = item
      this.$refs['add-main-prize'].show()
    },
    hideModal() {
      this.newMainPrize = null
      this.itemPrizeChange = null
      this.itemPrizeDelete = null
      this.modalType = 'add'
      this.$refs['add-main-prize'].hide()
    },
    async handleUpdateGetPrizeFrom() {
      const res = await this.updatePrizeMode({
        competitionId: this.$route.params.id,
        data: {
          prizeMode: this.selectedPrizeFrom,
        },
      })
      if (res) {
        this.showToastSuccess('Success', `Update prize mode  successfully!`)
        this.getCompDetail(this.$route.params.id)
      }
    },
    handleAddMoreOptionFlexi() {
      this.optionsFlexi.push('')
    },
    handleRemoveOptionFlexi() {
      this.optionsFlexi.pop()
    },
    formatPriceRollOver(value) {
      if (value < 0) return null
      if (value > this.maxValueFlexiRollOver()) return this.maxValueFlexiRollOver()
      return value
    },
    maxValueFlexiRollOver() {
      if (!this.comp.flexiRollOverAmount) return this.flexiRollOverWallet
      return this.comp.flexiRollOverAmount + this.flexiRollOverWallet
    },
    onClickInput() {
      const fileInput = this.$refs.fileInput
      fileInput.click()
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.parseCSV(reader.result)
      }
      reader.readAsText(file)
      this._resetInputFile()
    },
    parseCSV(csvData) {
      parse(csvData, { delimiter: ',' }, (err, data) => {
        if (err) {
          console.error(err)
          return
        }
        data.shift()
        const newList = data.map(i => i[1])
        this.reviewListPrize = newList
        if (newList) {
          this.$refs['list-review'].show()
          //  list-review
        }
      })
    },
    _resetInputFile() {
      this.$refs.fileInput.value = null
    },
    async handleSavePrizeList() {
      const formatList = this.reviewListPrize.map((item, index) => ({
        order: index + 1,
        value: +item,
        type: 'main',
      }))
      await this.deleteAllPrizes({
        competitionId: this.$route.params.id,
        type: 'main'
      })
      const res = await this.updatePrize({
        competitionId: this.$route.params.id,
        data: {
          prizes: formatList,
        },
      })
      if (res) {
        this.showToastSuccess('Success', 'Update main prize successfully!')
        this.getCompDetail(this.$route.params.id)
        this.$refs['list-review'].hide()
        this.reviewListPrize = []
      }
    },
    async handleRemoveEntryPrize() {

      const [
        res1,
        res2,
      ] = await Promise.all([
        await this.deleteAllPrizes({
          competitionId: this.$route.params.id,
          type: 'front'
        }),
        await this.deleteAllPrizes({
          competitionId: this.$route.params.id,
          type: 'back'
        }),
      ])

      if (res1 && res2) {
        this.showToastSuccess('Success', 'Update entry prize successfully!')
        this.getCompDetail(this.$route.params.id)
        this.frontEntryList = this.frontEntryList.map(item => {
          return { ...item, value: '' }
        })
        this.backEntryList = this.backEntryList.map(item => {
          return { ...item, value: '' }
        })

      }
    },
    handleRemoveAllLegs() {
      this.$refs.modal.show()
    },
   async handleSubmitModal() {
      this.loadingDeleteAllLegs = true
     try {
    
        const newList = this.dataDetail.legs
          .map(async item => {
            const data = await this.deleteEntry(item.entryId)
            return data
          })
  
        Promise.all(newList).then(result => {
          this.loadingDeleteAllLegs = false
          this.showToastSuccess('Success', 'Delete all legs successfully!')
          this.getCompDetail(this.$route.params.id)
          const newListPicked = this.listEventPicked.map(item => {
            return {
              legId: item.legId, title: item.title, value: ''
          }
          })
        this.setPickListEvent(newListPicked)

        })
        
      } catch (error) {
        this.loadingDeleteAllLegs = false
        
      }
    }


  },
  setup() {
    const { showToastSuccess, showToastError } = useToastHandler()

    return {
      showToastSuccess,
      showToastError,
      formatDate,
      formatDateSend,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.banner-img {
  max-width: 100%;
}
</style>
