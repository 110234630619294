var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!_vm.eventId)?_c('b-card',[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('div',{staticClass:"d-flex my-2"},[_c('h4',{staticClass:"mb-0 mr-2"},[_vm._v(" Choose sport ")]),_c('b-form-radio-group',{attrs:{"id":"radio-league","options":_vm.optionsSport,"name":"radio-league"},model:{value:(_vm.selectedSport),callback:function ($$v) {_vm.selectedSport=$$v},expression:"selectedSport"}})],1)]),_c('b-row',[_c('b-col',{staticStyle:{"flex-direction":"column"},attrs:{"cols":"12","lg":"4"}},[_c('label',[_vm._v("Start time")]),_c('v-select',{attrs:{"options":_vm.orderOptions},model:{value:(_vm.sortOrderStartTime),callback:function ($$v) {_vm.sortOrderStartTime=$$v},expression:"sortOrderStartTime"}})],1),_c('b-col',{staticStyle:{"display":"flex","justify-content":"end","height":"40px","margin-top":"20px"},attrs:{"cols":"12","lg":"8"}},[_c('b-button',{attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.handleCreateLeg}},[[_vm._v(" Create leg ")]],2)],1)],1)],1),_c('b-table',{ref:"refRacingListTable",attrs:{"items":_vm.fetchListEvent,"fields":_vm.columns,"responsive":"","primary-key":"racing","show-empty":"","no-sort-reset":true,"sticky-header":"800px"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(_vm.fetchListEvent.length === 0)?_c('div',{staticClass:"text-center my-2"},[_vm._v(" No matching records found ")]):_vm._e()]},proxy:true},{key:"cell(event)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"font-weight-bold d-block text-nowrap text-body cursor-pointer"},[_vm._v(" "+_vm._s(item.meetingName)+" ")])])]}},{key:"cell(raceNumber)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"font-weight-bold d-block text-nowrap text-body cursor-pointer"},[_vm._v(" "+_vm._s(item.number)+" ")])])]}},{key:"cell(raceName)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold",staticStyle:{"width":"90px"}},[_vm._v(" "+_vm._s(_vm.formatTime(item.startTime, 'dd-MM-yyyy'))+" ")])])]}},{key:"cell(time)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('div',{staticClass:"d-flex align-items-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatTime(item.startTime))+" ")])])]}},{key:"cell(choose)",fn:function(row){return [_c('b-dropdown',{attrs:{"id":row.item.id,"variant":"link","no-caret":"","boundary":"viewport","disabled":_vm.disableSelectLeg(row.item)},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.showLeg(row.item))?_c('div',{staticStyle:{"font-weight":"900","display":"flex","gap":"4px","align-items":"center"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_c('span',[_vm._v("Leg "+_vm._s(_vm.showLeg(row.item).legNumber))]),_c('span',{staticStyle:{"padding":"2px","border":"1px solid grey","color":"red"}},[_vm._v("x")])]):_c('div',{staticStyle:{"cursor":"pointer"}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"EditIcon","size":"16"}})],1)]},proxy:true}],null,true)},[_vm._l((_vm.legList),function(i){return _c('b-dropdown-item',{key:i.number,staticStyle:{"text-align":"center"},attrs:{"disabled":_vm.disabledLeg(i)},on:{"click":function($event){return _vm.handleSelectLeg(row.item, i)}}},[_vm._v(" "+_vm._s(i.value)+" ")])})],2)]}}],null,false,3755533232)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }